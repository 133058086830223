$(document).ready(function() {
  const $countryPricingTable = $('.js-country-pricing-configurations-table');

  if ($countryPricingTable.length > 0) {
    $countryPricingTable.on('nested:fieldAdded', function(event){
      const $select = event.field.find('select');

      $select.on('mousedown', function(event) {
        // Get a list of the countries that have already been persisted.
        // We only search for visible persisted countries because `nested_forms` will hide a row when
        // a user clicks the remove button.
        //
        const persistedCountries = $countryPricingTable.find('.js-select-country-pricing:visible').map(function() {
          return $(this).text();
        }).toArray();

        // Get a list of the selected countries in the select inputs excluding the currently clicked select.
        // We only search for visible select inputs because `nested_forms` will hide a row when a user
        // clicks the remove button.
        // We also want to ignore the blank value option because it is not a country.
        //
        const currentlySelectedCountries = $countryPricingTable.find('select:visible').not(event.currentTarget).map(function() {
          const selectedOption = $(this).find('option:selected');
          if (selectedOption.val() !== '') {
            return selectedOption.text();
          }
        }).toArray();

        const countriesToHide = currentlySelectedCountries.concat(persistedCountries);
        const $options = $(event.currentTarget).find('option');

        // Only attempt to filter the collection if there are countries to hide, otherwise we can
        // just make sure all options are shown.
        //
        $options.show();

        if (countriesToHide.length > 0) {
          $options.filter(function(index) {
            var optionText = $($options[index]).text()

            return countriesToHide.indexOf(optionText) > -1;
          }).hide();
        }
      });
    });
  }
});
