/* globals HtmlToElements: true */

class Notices {
  addFlash(content) {
    const elements = HtmlToElements.nodesForHtml(content)
    elements.querySelector(".alert").style.display = null
    document.body.appendChild(elements)
  }

  hideFlashes() {
    document.querySelectorAll(".notices .alert").forEach(element => element.classList.add("d-none"))
  }
}

window.Notices = new Notices()
