import Clipboard from "clipboard"

class ClipboardWrapper {
  static initClipboard() {
    const clipboard = new Clipboard(".js-clipboard")

    clipboard.on("success", (event) => { ClipboardWrapper.successHandler(event) })
  }

  static initClipboardForModal(modal) {
    const clipboard = new Clipboard(".js-clipboard", { container: modal })

    clipboard.on("success", (event) => { ClipboardWrapper.successHandler(event) })
  }

  // When the element is clicked, we will try and initiate a popover. If no popover is configured,
  // nothing will happen.
  //
  // Have a look at the CopyToClipboardButton ViewComponent to see some sensible defaults.
  //
  static successHandler(event) {
    $(event.trigger).popover("show")

    const hidePopover = () => { $(event.trigger).popover("hide") }
    setTimeout(hidePopover, 1500)
  }
}

window.initClipboard = ClipboardWrapper.initClipboard
window.initClipboardForModal = ClipboardWrapper.initClipboardForModal

$(document).ready(function() {
  window.initClipboard()
})

