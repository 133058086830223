import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "form",
    "input",
    "submitButton"
  ]

  inputWasUpdated(event) {
    if (this.inputHasSixDigits()) {
      this.submitFormIfAllInputsAreValid()
    }
  }

  inputHasSixDigits() {
    return this.inputTarget.value.length == 6
  }

  submitFormIfAllInputsAreValid() {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.value = "Submitting..."
    this.formTarget.submit()
  }
}
