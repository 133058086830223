import Cleave from "cleave.js"

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-with-formatter');
  elements.forEach((element) => {
    switch(element.dataset.formatter) {
      case 'date':
        new Cleave(element, { date: true, delimiter: '/', datePattern: ['m', 'd', 'Y'] });
        break;
      case 'phone':
        new Cleave(element, { blocks: [3, 3, 4], delimiter: '-' });
        break;
      case 'zip_code':
        new Cleave(element, { blocks: [5, 4], delimiters: ['-'], delimiterLazyShow: true });
        break;
      default:
        console.error('Unhandled format type for', element);
    }
  });
});
