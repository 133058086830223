$(document).ready(function() {
  var updatePhoneStatus = function(element) {
    $.ajax({
      url: $(element).data('url'),
      type: 'PUT',
      dataType: 'json',
      success: function(data) {
        $('#participant-application-' + data.id).find('i.js-phone-icon')
          .toggleClass('fa-phone')
          .toggleClass('fa-phone-square');
      }
    });
  };

  var participantPhoneToggle = function(_, element) {
    var $element = $(element);
    var $phoneToggle = $element.find('.js-phone-icon');

    $phoneToggle.on('click', function() {
      if(window.confirm('Change this participant\'s call status?')) {
        updatePhoneStatus(element);
      }
    });
  };

  $('*[data-behavior="InterExchange.ParticipantPhoneToggle"]').each(participantPhoneToggle);
});
