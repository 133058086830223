// Focus input on modal load
$(document).on('shown.bs.modal', '#js-copy-employer-application-modal', (event) => {
  $(event.target).find(':text:first').trigger('focus');
});

// Clicking Only My Hosts triggers search after some time to try to allow double clicks etc
$(document).on('change', '#js-copy-employer-application-modal .js-only-my-hosts', () => {
  $('#js-copy-employer-application-modal .js-search-button').trigger('click');
});

// Searching for Employer Applications
$(document).on('click', '#js-copy-employer-application-modal .js-search-button', (event) => {
  const form = $(event.target).closest('form');

  if (form.find('#js-search').val().length <= 0) { return false; }

  const url = form.prop('action');
  const searchResults = form.find('.js-search-result-content');

  $('#js-select-all-employer-applications').prop('checked', false);

  searchResults.fadeOut(() => {
    searchResults.load(`${ url }?${ form.serialize() }`, (response, status) => {
      if (status == 'error') {
        searchResults.text('There was an error.');
      }
      else {
        searchResults.fadeIn(() => {
          const resultCount = searchResults.find('.list-group-item.employer_application').length;

          form.find('.js-employer-application-search-result-count').text(resultCount);
        });
      }
    });
  });

  return false;
});

// Selecting all returned results
$(document).on('change', '#js-select-all-employer-applications', (event) => {
  const checked = event.target.checked;
  $('#js-copy-employer-application-modal').find('.js-search-results :checkbox').prop('checked', checked);

  return false;
});

// Copying the Applications
$(document).on('click', '#js-copy-employer-application', (event) => {
  event.stopPropagation();
  event.preventDefault();

  const selectedEmployerApplications = $('#js-copy-employer-application-modal').find('.js-search-results .employer_application :checked');
  if (selectedEmployerApplications.length == 0) {
    alert('There are no Host Employer Applications selected');
    return;
  }

  const copyButton = event.currentTarget;
  const confirmText = copyButton.dataset.confirmText;

  if (confirm(confirmText) == false) { return; }

  copyButton.setAttribute('disabled', true);

  const sourceEmployerApplicationId = copyButton.dataset.sourceEmployerApplicationId;

  selectedEmployerApplications.each((index, employerApplicationCheckBox) => {
    const url = employerApplicationCheckBox.dataset.copyUrl;
    const copier = new EmployerApplicationCopier(sourceEmployerApplicationId, url);
    const employerApplicationRow = new EmployerApplicationRow($(employerApplicationCheckBox).closest('.employer_application'));

    employerApplicationRow.startCopy();

    copier.
      copy().
      done(() => {
        employerApplicationRow.copyDone();

        copyButton.removeAttribute('disabled');
      }).
      catch((response) => {
        employerApplicationRow.copyFailed(response.statusText);

        copyButton.removeAttribute('disabled');
      });
  });

  return false;
});

// Click on the row selects the checkbox
$(document).on('click', '#js-copy-employer-application-modal .js-search-results .employer_application', (event) => {
  if (event.target.type !== 'checkbox') {
    const checkBox = $(event.currentTarget).find(':checkbox');
    checkBox.prop('checked', !checkBox[0].checked);
  }
});

class EmployerApplicationCopier {
  constructor(sourceId, url) {
    this.sourceEmployerApplicationId = sourceId;
    this.destinationEmployerApplicationUrl = url;
  }

  copy() {
    return $.ajax({
      url: this.destinationEmployerApplicationUrl,
      type: 'PUT',
      dataType: 'json',
      data: { source_employer_application_id: this.sourceEmployerApplicationId },
    });
  }
}

class EmployerApplicationRow {
  constructor(element) {
    this.element = element;
    this.itemSuccessCssClass = 'list-group-item-success';
    this.itemFailCssClass = 'list-group-item-danger';
    this.itemCopyingCssClass = 'list-group-item-actionable-started';
  }

  startCopy() {
    this.element.removeClass(this.itemSuccessCssClass);
    this.element.removeClass(this.itemFailCssClass);
    this.element.addClass(this.itemCopyingCssClass);
    this.removeError();
  }

  copyDone() {
    this.element.addClass(this.itemSuccessCssClass);
    this.element.removeClass(this.itemCopyingCssClass);
  }

  copyFailed(message) {
    this.element.addClass(this.itemFailCssClass);
    this.element.removeClass(this.itemCopyingCssClass);
    this.addError(message);
  }

  removeError() {
    this.element.find('.error-content').text('');
  }

  addError(message) {
    this.element.find('.error-content').text(`Error: ${ message }`);
  }
}
