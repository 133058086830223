import consumer from "./consumer"

class Ds2019Card {
  _documentUrl
  _formUrl
  _name
  _status
  _createChannel
  _processChannel
  _error
  _errorAlert

  constructor(element) {
    this.element = element

    this._documentUrl = this.element.querySelector(".js-ds2019-document-url")
    this._formUrl = this.element.querySelector(".js-ds2019-form-url")
    this._name = this.element.querySelector(".js-ds2019-name")
    this._status = this.element.querySelector(".js-ds2019-status")
    this._statusText = this.element.querySelector(".js-ds2019-status span")
    this._statusIcon = this.element.querySelector(".js-ds2019-status i")
    this._error = this.element.querySelector(".js-ds2019-error")
    this._errorAlert = this._error.querySelector(".alert")
  }

  id() {
    return this.element.dataset.id
  }

  ds2019_batch_id() {
    return this.element.dataset.ds2019_batch_id
  }

  sevis_id() {
    return this.element.dataset.sevis_id
  }

  status() {
    return this.element.dataset.status
  }

  updateStatus(data) {
    this.element.dataset.status = data.status

    if(data.form_url) {
      this._formUrl.innerHTML = data.file_name
      this._formUrl.href = data.form_url
      this._formUrl.classList.add("d-block")
      this._formUrl.classList.remove("d-none")
      this._name.classList.add("d-none")
      this._name.classList.remove("d-block")
    } else {
      this._name.innerHTML = data.file_name
      this._name.classList.add("d-block")
      this._name.classList.remove("d-none")
      this._formUrl.classList.add("d-none")
      this._formUrl.classList.remove("d-block")
    }

    if(data.document_url) {
      this._documentUrl.href = data.document_url
      this._documentUrl.classList.add("d-block")
      this._documentUrl.classList.remove("d-none")
    } else {
      this._documentUrl.href = ""
      this._documentUrl.classList.add("d-none")
      this._documentUrl.classList.remove("d-block")
    }

    this._status.className = this._status.className.replace(/text-.*$/, `text-${data.status}`)
    this._statusIcon.className = this._statusIcon.className.replace(/fa-.*$/, `fa-${data.icon}`)
    this._statusText.innerHTML = data.status

    if(data.icon_spin) {
      this._statusIcon.classList.add("fa-spin")
    } else {
      this._statusIcon.classList.remove("fa-spin")
    }

    if(this.status() == "error") {
      this._error.classList.remove("d-none")
      this._errorAlert.innerHTML = data.error
    }

    this.element.className = this.element.className.replace(/loading-skeleton/, '')
  }
}

class Ds2019Batch {
  _status
  _statusText
  _statusIcon

  constructor(element) {
    this.element = element

    this._status = this.element.querySelector(".js-ds2019-batch-status")
    this._statusText = this.element.querySelector(".js-ds2019-batch-status span")
    this._statusIcon = this.element.querySelector(".js-ds2019-batch-status i")
  }

  id() {
    return this.element.dataset.id
  }

  status() {
    return this.element.dataset.status
  }

  updateStatus(data) {
    this.element.dataset.status = data.status

    this._status.className = this._status.className.replace(/text-.*$/, `text-${data.status}`)
    this._statusText.innerHTML = data.status
    this._statusIcon.className = this._statusIcon.className.replace(/fa-.*$/, `fa-${data.icon}`)
    if(data.icon_spin) {
      this._statusIcon.classList.add("fa-spin")
    } else {
      this._statusIcon.classList.remove("fa-spin")
    }
  }
}

var ds2019BatchSelector = document.querySelector(".js-ds2019-batch")

if(ds2019BatchSelector) {
  let ds2019Batch = new Ds2019Batch(ds2019BatchSelector)

  consumer.subscriptions.create(
    {
      channel: "Ds2019BatchChannel",
      id: ds2019Batch.id()
    },
    {
      received(data) {
        ds2019Batch.updateStatus(data)

        if(data.ds2019) {
          var ds2019CardSelector = document.querySelector(".js-ds2019-card[data-sevis_id='" + data.ds2019.sevis_id + "']")

          if(ds2019CardSelector) {
            let ds2019Card = new Ds2019Card(ds2019CardSelector)
            let ds2019Data = data.ds2019

            ds2019Card.updateStatus(ds2019Data)
          } else {
            console.warn("Could not find ds2019 card for id: .js-ds2019-card[data-sevis_id='" + data.ds2019.sevis_id + "']")
          }
        }
      }
    }
  )
}
