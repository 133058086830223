// This has been deprecated in favour of src/js/application/simple_select_toggle_elements.js
//
$(document).ready(function() {
  $('[data-clicking-toggles]').click(function (event) {
    event.preventDefault();
    const identifier = $(event.currentTarget).data('clicking-toggles');
    $(identifier).toggle();
  });

  $('[data-clicking-toggles-on]').click(function (event) {
    event.preventDefault();
    const identifier = $(event.currentTarget).data('clicking-toggles-on');
    $(identifier).show();
  });

  $('[data-clicking-toggles-off]').click(function (event) {
    event.preventDefault();
    const identifier = $(event.currentTarget).data('clicking-toggles-off');
    $(identifier).hide();
  });
});
