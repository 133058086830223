$(document).ready(function() {
  const $trainingPlanSupervisorSelect = $('#training_plan_phase_supervisor_id');
  const currentUserId = $trainingPlanSupervisorSelect.data('current-user-id');
  const $saveAndSignButton = $('input[type="submit"][name="save_and_sign"]');

  $trainingPlanSupervisorSelect.change(function() {
    const selectedPhaseSupervisorId = $(this).val();

    $saveAndSignButton.prop('disabled', !(currentUserId == selectedPhaseSupervisorId));
  });
});
