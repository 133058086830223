import consumer from "./consumer"

class Ds2019DocumentItem {
  _card
  _alert
  _deleteButton
  _documentContent
  _documentStatus
  _documentUrl
  _informationContent
  _downloadButton
  _refreshButton
  _signedAt
  _signedBy
  _travelValidationContent

  constructor(element, card) {
    this.element = element
    this._card = card

    this._alert = this.element.querySelector(".js-ds2019-sign-form-alert")
    this._deleteButton = this.element.querySelector(".js-ds2019-sign-document-delete-btn")
    this._documentContent = this.element.querySelector(".js-ds2019-document-content")
    this._documentStatus = this.element.querySelector(".js-ds2019-sign-document-status")
    this._documentUrl = this.element.querySelector(".js-ds2019-sign-document-url")
    this._downloadButton = this.element.querySelector(".js-ds2019-sign-document-download-btn")
    this._refreshButton = this.element.querySelector(".js-ds2019-sign-document-refresh-btn")
    this._signedBy = this.element.querySelector(".signed-by")
    this._signedAt = this.element.querySelector(".signed-at")
    this._travelValidationContent = this.element.querySelector(".js-ds2019-document-travel-validation")
  }

  id() {
    return this.element.dataset.id
  }

  status() {
    return this.element.dataset.status
  }

  updateStatus(status, documentURL, documentName, signedByName, signedDate) {
    this._alert.querySelectorAll(".signing").forEach(element => {
      element.classList.add("d-none")
    });

    this._alert.classList.remove("alert-info")

    if(status == "signed") {
      this._card.showInformationContent()

      this._deleteButton.classList.remove("disabled")
      this._deleteButton.removeAttribute("aria-disabled")

      this._signedBy.textContent = signedByName
      this._signedAt.textContent = signedDate

      this._alert.querySelectorAll(".signed").forEach(element => {
        element.classList.remove("d-none")
      });

      this._alert.classList.add("alert-success")

      this._downloadButton.href = documentURL
      this._downloadButton.classList.remove("disabled")
      this._downloadButton.removeAttribute("aria-disabled")

      this._refreshButton.remove()

      setTimeout(() => { $(".js-ds2019-sign-form-alert").alert("close") },
        4000
      )
    } else if(status == "error") {
      this._alert.classList.remove("alert-info")
      this._alert.classList.add("alert-danger")

      this._refreshButton.classList.remove("disabled")
      this._refreshButton.removeAttribute("aria-disabled")

      this._downloadButton.remove()
      this._deleteButton.remove()

      this._alert.querySelectorAll(".error").forEach(element => {
        element.classList.remove("d-none")
      });

      const linkText = document.createElement("span")
      linkText.innerHTML = this._documentUrl.innerHTML
      linkText.classList = this._documentUrl.classList
      this._documentUrl.parentNode.replaceChild(linkText, this._documentUrl)

      // Remove any elements we do not want to show once there is an error.
      this._documentStatus.remove()
      this._travelValidationContent?.remove()
    }

    this._documentContent.toggleSkeleton(false)

    // Once the status has been updated there is no longer any reason to remain subscribed.
    Rails.ds2019_channel.unsubscribe()
  }
}

class Ds2019DocumentCard {
  _documentGroup
  _documentItems
  _informationContent

  constructor(element) {
    this.element = element

    this._informationContent = this.element.querySelector(".js-ds2019-form-information")
    this._documentGroup = this.element.querySelector(".js-ds2019-document-group")
    this._documentItems = []
    this._documentGroup.querySelectorAll(".js-ds2019-document-item").forEach((element) => {
      this._documentItems.push(new Ds2019DocumentItem(element, this))
    });
  }

  documentItems() {
    return this._documentItems
  }

  showInformationContent() {
    this._informationContent.classList.remove("d-none")
  }
}

var ds2019DocumentCardElement = document.querySelector(".js-ds2019-document-card")

if(ds2019DocumentCardElement != null) {
  const ds2019DocumentCard = new Ds2019DocumentCard(ds2019DocumentCardElement)

  ds2019DocumentCard.documentItems().forEach((ds2019DocumentItem) => {
    if(ds2019DocumentItem.id() !== undefined && ds2019DocumentItem.status() !== "signed") {
      Rails.ds2019_channel = consumer.subscriptions.create(
        {channel: "Ds2019SignChannel", id: ds2019DocumentItem.id(), status: ds2019DocumentItem.status()},
        {
          received(data) {
            ds2019DocumentItem.updateStatus(
              data.status,
              data.document_url,
              data.document_name,
              data.signed_by_name,
              data.signed_date
            )
          },
        })
    }
  });
}
