class HtmlToElements {
  static nodesForHtml(html) {
    const template = document.createElement("template")
    template.innerHTML = html

    return(template.content.firstElementChild)
  }
}

window.HtmlToElements = HtmlToElements
