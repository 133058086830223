document.addEventListener("input", (event) => {
  const form = event.target.closest(".js-form-with-required-fields")

  if (form) {
    const hasEmptyRequiredInput = Array.from(form.querySelectorAll("input.required")).some(element => element.value.trim().length == 0)
    const submitButton = form.querySelector("input[type=submit]")

    if (hasEmptyRequiredInput) {
      submitButton.setAttribute("disabled", true)
    }
    else {
      submitButton.removeAttribute("disabled")
    }
  }
});
