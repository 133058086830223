$(document).ready(function() {
  InterExchange.MarkdownPreview = function(_, element) {
    const $container = $(element),
          $inputWithMarkdown = $container.find('.js-markdown-content-input'),
          $markdownDestination = $container.find('.js-markdown-content-container'),
          $markdownNoPreviewMessage = $container.find('.js-markdown-content-container-no-preview-card'),
          convertMarkdownPath = $inputWithMarkdown.data('convert-markdown-path');

    // When the user opens the "Preview" tab, convert the contents of $inputWithMarkdown into Markdown
    // and display in the $markdownDestination
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (event) {
      if (event.target.getAttribute('aria-controls') == 'preview') {
        $markdownNoPreviewMessage.toggle($inputWithMarkdown.val().length == 0)
        $markdownDestination.load(convertMarkdownPath, {markdown: $inputWithMarkdown.val()});
      }
    });
  };

  $('.js-markdown-preview-container').each(InterExchange.MarkdownPreview);
});
