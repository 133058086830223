import { Application } from "@hotwired/stimulus"

window.Stimulus = Application.start()
// Stimulus.debug = true

import controllers from "./**/*_controller.js"

controllers.forEach((controller) => {
  window.Stimulus.register(controller.name, controller.module.default)
})

