import { Controller } from "@hotwired/stimulus"
import * as NiceSelect from "nice-select2";
import Mustache from 'mustache';

export default class extends Controller {
  static targets = [
    "cooperatorList",
    "template"
  ]

  connect() {
    // Holds NiceSelect instances for later update
    this.selectInstances = {};

    // Convert all selects on the page
    document.querySelectorAll("select.search-select").forEach((node) => {
      this.instantiateSelect(node);
    });

    this.restrictSelects();
  }

  /**
   * To render a new cooperator row, we use Mustache to render a template defined in the underlying view.
   * Because that view template is rendered server-side, we can use all the nice form architecture that Rails provides.
   */
  addCooperatorRow() {
    var template = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    var renderedHtml = Mustache.render(template, { limit: 0, count: 0 });

    this.cooperatorListTarget.insertAdjacentHTML("beforeend", renderedHtml);
    var node = this.cooperatorListTarget.lastElementChild;

    this.instantiateSelect(node.querySelector("select"));

    this.restrictSelects();
  }

  removeCooperatorRow(event) {
    event.preventDefault();
    var node = event.target.closest('tr');
    node.remove();

    this.restrictSelects();
  }

  /**
   * Restrict Cooperator dropdowns on the page to prevent duplicate selections of Cooperators.
   */
  restrictSelects(e) {
    if (e) {
      var value = e.target.value;
      e.target.querySelectorAll("option").forEach((item) => item.removeAttribute('selected'));
      e.target.querySelector(`option[value="${value}"]`).setAttribute("selected", "selected");
    }

    document.querySelectorAll("select.search-select").forEach((selectNode) => {
      var values = Array.from(document.querySelectorAll("select.search-select") || []).filter(node => node !== selectNode).map((node) => node.value);

      selectNode.querySelectorAll("option").forEach((node) => node.disabled = false);

      values.forEach((value) => {
        selectNode.querySelector(`option[value="${value}"]`).disabled = true;
      });

      var instance = this.selectInstances[selectNode.id];

      // Update the select dropdown from the select node to reflect disabled changes
      instance.update();
    })
  }

  instantiateSelect(node) {
    var instance = NiceSelect.bind(node, {
      searchable: true
    });

    this.selectInstances[node.id] = instance;
  }
}
