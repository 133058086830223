/* globals PaymentForm: true */
/* globals PaymentFormField: true */
/* globals Button: true */

class ParticipantPaymentForm {
  constructor($form) {
    this.$form = $form;
  }

  initializeForm() {
    this.submitButton = new Button(this.$form.find('#js-submit-button'));
    this._assignInputs();
    this._initializeInputValidations();

    this.$paymentForm = new PaymentForm(this.$form, this.$form.data('client-authorization'), this.inputs, this.submitButton);
    this.$paymentForm.initializeForm();
  }

  _assignInputs() {
    this.inputs = {};
    this.inputs.paymentAmount = new PaymentFormField($('#js-payment-amount'), { required: true });
    this.inputs.cardholderName = new PaymentFormField($('#js-cardholder-name'), { required: true });
    this.inputs.number = new PaymentFormField(this.$form.find('#card-number').closest('.form-group'), { braintreeHostedField: true, required: true });
    this.inputs.expirationDate = new PaymentFormField(this.$form.find('#cc-expiration').closest('.form-group'), { braintreeHostedField: true, required: true });
    this.inputs.cvv = new PaymentFormField(this.$form.find('#cvv').closest('.form-group'), { braintreeHostedField: true, required: true });
    this.inputs.notes = new PaymentFormField(this.$form.find('#js-payment-note-form-group'), { required: false });
  }

  _initializeInputValidations() {
    this.inputs.paymentAmount.addValidator(function(paymentFormField) {
      paymentFormField.setIsValid(parseFloat(paymentFormField.getValue()) >= 0.1);
    });

    // Payment Amount can be set on page load for the "Suggested Amount" payment type
    // In this case we'll want to set the input to be valid. In other cases we don't want to run
    // validation as the empty input will be marked as invalid, which is misleading on a page load.
    if (this.inputs.paymentAmount.isPopulated()) {
      this.inputs.paymentAmount.validate();
    }
  }
}

$(document).ready(function() {
  const $form = $('#js-participant-payment-form');
  if ($form.length > 0) {
    const participantPaymentForm = new ParticipantPaymentForm($form);
    participantPaymentForm.initializeForm();
  }
});
