$(document).on("shown.bs.modal", ".js-offer-draft-job-offer-modal", (event) => {
  new OnReviewModal(event.target)
})

$(document).on('click', '.js-offer-draft-job-offer-modal input:submit', (event) => {
  $(event.currentTarget).button('loading')
})

$(document).on('ajax:before', '.js-offer-draft-job-offer-modal', (event) => {
  const modal = $(event.currentTarget)
  modal.find('div.invalid-feedback, .alert-danger').remove()
  modal.find('.form-group').removeClass('form-group-invalid')
  modal.find('.is-invalid').removeClass('is-invalid')
})

$(document).on('ajax:success', '.js-offer-draft-job-offer-modal', (event) => {
  const content = event.detail[0]
  const matchId = content.match_id
  const panel = $(`#match_${ matchId }`)
  const modal = $('.modal.show')

  modal.modal('hide')
  panel.replaceWith(new Alert(content.status).html())
})

$(document).on('ajax:error', '.js-offer-draft-job-offer-modal', (event) => {
  const response = event.detail[0]
  const modal = $(event.currentTarget)
  const button = modal.find('input:submit')
  button.button('reset')

  if (response.errors) {
    const parentErrors = response.errors.parent.base; // YAGNI: only need base errors for now
    const childErrors = response.errors.children
    const errorClasses = 'form-group-invalid'
    const group = modal.find('.list-group.participants')

    if (parentErrors) {
      const messages = parentErrors.map((message) => `${ message }<br />`)

      group.before(new Alert({body: messages, type: 'danger'}).html())
    }

    for (const [wtParticipantApplicationId, errors] of Object.entries(childErrors)) {
      const formFinder = `[name*="[params_for_individuals[${ wtParticipantApplicationId }]]`

      for (const [fieldName, message] of Object.entries(errors)) {
        const fieldFinder = `${ formFinder }[${ fieldName }]"]`
        const $input = modal.find(fieldFinder)
        const errorSpan = `<div class="invalid-feedback d-block">${ message }</div>`
        const formGroup = $input.closest('.form-group')
        const toggledInput = formGroup.parent().next('.toggled')

        // For textareas we want the error below the textarea, for inputs we show it next to the label
        if ($input.is('textarea')) {
          formGroup.addClass(errorClasses)
          $input.parent().append(errorSpan)
        } else {
          formGroup.addClass(errorClasses).append(errorSpan)
        }
        $input.addClass('is-invalid')
        toggledInput.addClass(errorClasses)
      }
    }
  }
})

$(document).on('keypress', '.js-on-review-expires-on', (event) => {
  if (event.which == 13) { // Enter key
    event.preventDefault(); // submit form in changeDate
    $(event.currentTarget).trigger('changeDate')
  }
})

$(document).on('click', '.js-on-review-expires-on', (event) => {
  $(event.currentTarget).datepicker('show'); // if it was hidden due to pressing enter, re-open
})

$(document).on('changeDate blur', '.js-on-review-expires-on', (event) => {
  const input = $(event.currentTarget)

  if (input.val() == input.data('oldValue')) { return; }

  const groupContainer = input.closest('.match')
  const form = input.closest('form')
  const groupForms = groupContainer.find('form')
  const groupInputs = groupForms.find('.datepicker')

  form.on('ajax:before', () => {
    groupInputs.removeClass('updating error success').addClass('updating')
  })

  form.on('ajax:complete', () => {
    groupInputs.removeClass('updating')
    groupInputs.val(input.val()).data('oldValue', input.val())
  })

  form.on('ajax:success', (event) => {
    const responseJson = event.detail[0]
    if ("errors" in responseJson && Object.keys(responseJson["errors"]).length > 0) {
      groupInputs.addClass('error')
    }
    else {
      groupInputs.addClass('success')
      setTimeout(() => { groupInputs.removeClass('success'); }, 3000)
    }
  })

  form.on('ajax:error', (event) => {
    groupInputs.addClass('error')
    const content = event.detail[0]
    const errors = content.errors
    if (errors) { console.error('Error', errors.join(', ')) }
  })

  Rails.fire(form[0], 'submit')
})

class OnReviewModal {
  constructor(element) {
    this.element = element

    this.draftJobOfferForms = [...this.element.querySelectorAll(".js-draft-job-offer")].
      map(element => new DraftJobOfferForm(element))
  }
}

class DraftJobOfferForm {
  constructor(container) {
    this.container = container
    this.container.dataset.changed = false

    this.additionalDescriptionField = container.querySelector(".js-additional-description")
    this.additionalDescriptionHintTextField = container.querySelector(".js-additional-description-help-text")
    this.additionalDescriptionCheckbox = container.querySelector(".js-requires-additional-description")
    this.hoursField = container.querySelector(".js-hours-input")
    this.overtimeField = container.querySelector(`[name="${ container.querySelector(".js-overtime-input").name }"]`)
    this.overtimeRateField = container.querySelector(".js-overtime-rate-input")
    this.positionCopyTextField = container.querySelector(".js-position-copy-text")
    this.positionDescriptionField = container.querySelector(".js-position-description-container")
    this.positionField = container.querySelector(".js-position-input")
    this.tippedField = container.querySelector(`[name="${ container.querySelector(".js-tipped-input").name }"]`)
    this.tipsInAdditionToWageField = container.querySelector(`[name="${ container.querySelector(".js-tips-in-addition-input").name }"]`)
    this.wageField = container.querySelector(".js-wage-input")

    this.positionField.addEventListener("change", this.positionChanged.bind(this))
    this.container.addEventListener("change", this.registerChange.bind(this))
    this.container.addEventListener("trix-change", this.registerChange.bind(this))
  }

  positionChanged(_event) {
    if (this.formHasChanges()) {
      window.confirmDialog("This will overwrite the position details you have entered with the details from your Job Listing. Are you sure?").
        then((confirmed) => {
          if (confirmed) {
            this.populateForm()
          }
        })
    }
    else {
      this.populateForm()
    }
  }

  formHasChanges() {
    return(this.container.dataset.changed == "true")
  }

  registerChange(event) {
    if (event.target == this.positionField) { return }

    this.container.dataset.changed = true
  }

  populateForm() {
    const jobListing = JobListing.for(this.positionField.value)
    const jobListingMap = new JobListingDraftJobOfferFormAdaptor(jobListing).mapForDraftJobOfferForm()

    this.positionDescriptionField.classList.add("d-none")
    this.additionalDescriptionCheckbox.checked = false
    this.container.querySelectorAll(".toggled").forEach(element => element.classList.add("d-none"))

    Object.entries(jobListingMap).forEach(([key, value]) => {
      const element = this[key]

      if (element.tagName == "INPUT") {
        this.populateInput(element, value)
      }
      else {
        this.populateTextElement(key, element, value)
      }
    })

    this.container.dataset.changed = false
  }

  populateInput(element, value) {
    if (["text", "number"].includes(element.type)) {
      element.value = value
    }
    else if ("hidden" == element.type) {
      this.setRadio(element, value)
    }
  }

  populateTextElement(key, element, value) {
    if (key == "positionDescriptionField") {
      if (value.length) {
        element.querySelector(".card-text").innerText = value
        element.classList.remove("d-none")
      }
      else {
        const position = Position.for(this.positionField.value)
        if (position.description.length) {
          element.querySelector(".card-text").innerHTML = position.description
          element.classList.remove("d-none")
        }
      }
    }
    else if (key == "additionalDescriptionField") {
      const editor = element.querySelector("trix-editor").editor
      const checkbox = this.additionalDescriptionCheckbox
      editor.loadHTML("")
      editor.setSelectedRange([0, 0])
      editor.insertHTML(value)

      if ((value.length && !checkbox.checked) || (!value.length && checkbox.checked)) {
        checkbox.click()
      }
    }
    else if (key == "additionalDescriptionHintTextField") {
      if (value.length) {
        element.innerHTML = value
      }
      else {
        element.innerHTML = "We'll show this on their Job Offer"
      }
    }
    else if (key == "positionCopyTextField" && value.length) {
      window.Notices.addFlash(value)
    }
  }

  setRadio(element, value) {
    if (value == null) { return }

    this.container.querySelectorAll(`[name="${ element.name }"]`).forEach((element) => {
      if (element.type == "radio") {
        if (element.value.toString() == value.toString()) {
          element.click()
        }
        else {
          element.checked = false
        }
      }
    })
  }
}

class JobListing {
  constructor(jobListingData) {
    this.jobListingData = jobListingData

    this.parseJobListingData()
  }

  parseJobListingData() {
    const json = JSON.parse(this.jobListingData.innerHTML)

    Object.entries(json).forEach(([key, value]) => {
      if (value != null) {
        this[key] = value
      }
      else {
        this[key] = ""
      }
    })
  }

  static for(positionId) {
    const jobListingData = document.querySelector(`.js-job-listing-data[data-position-id="${ positionId }"]`) || document.querySelector(".js-job-listing-data:not([data-position-id])")

    return(new this(jobListingData))
  }
}

class JobListingDraftJobOfferFormAdaptor {
  constructor(jobListing) {
    this.jobListing = jobListing
  }

  mapForDraftJobOfferForm() {
    return(
      {
        additionalDescriptionField: this.jobListing.additional_description,
        additionalDescriptionHintTextField: this.jobListing.additional_description_hint_text,
        hoursField: this.jobListing.hours,
        overtimeField: this.jobListing.has_overtime,
        overtimeRateField: this.jobListing.overtime_rate,
        positionCopyTextField: this.jobListing.copy_text,
        positionDescriptionField: this.jobListing.position_description,
        tippedField: this.jobListing.has_tips,
        tipsInAdditionToWageField: this.jobListing.has_tips_sometimes,
        wageField: this.jobListing.wage
      }
    )
  }
}

class Position {
  constructor(positionData) {
    this.positionData = positionData

    if (this.positionData) {
      this.description = this.positionData.innerHTML
    }
    else {
      this.description = ""
    }
  }

  static for(positionId) {
    const positionData = document.querySelector(`.js-position-data[data-position-id="${ positionId }"]`)

    return(new this(positionData))
  }
}
