$(document).ready(function() {
  $('.js-document-input').each(function (_, element) {
    const $documentCard = $(element);
    const $documentFilepickerContainer = $documentCard.find('.js-document-filepicker-container');
    const $filePickerButton = $documentFilepickerContainer.find('.js-filepicker-input');
    const $filePickerInput = $documentFilepickerContainer.find(`#${ $filePickerButton.attr('input_id') }`);
    const $documentAttachmentContainer = $documentCard.find('.js-document-attachment-container');
    const $documentUploadButton = $documentAttachmentContainer.find('.js-document-upload-btn');
    const $documentRemoveButton = $documentAttachmentContainer.find('.js-document-remove-btn');

    $documentUploadButton.on('click', function(event) {
      event.preventDefault();
      $documentFilepickerContainer.find('button').click();
    });

    $documentRemoveButton.on('click', function(event) {
      event.preventDefault();
      $filePickerInput.val('');
      $documentAttachmentContainer.hide();
      $documentFilepickerContainer.show();
    });
  })
});
