$(document).ready(function() {
  var configureNotesContainers = function(event) {
    var $noteContainer = $(event.currentTarget);
    var title = $noteContainer.data('title');
    var notes = $noteContainer.data('notes');
    var url = $noteContainer.data('url');
    var $modal = $($noteContainer.data('target'));

    $modal.find('.modal-title').html(title);
    $modal.find('textarea').val(notes);
    $modal.find('form')
      .prop('action', url)
      .data('destination', '#' + $noteContainer.prop('id'));
    $($modal).modal('show');
  };

  $('*[data-behavior="InterExchange.ParticipantNotesModal"]').click(configureNotesContainers);

  var $notesModal = $('#js-notes-modal');
  $notesModal.find('form').on('ajax:success', function() {
    var noteText = $notesModal.find('textarea').val();
    var $note = $($notesModal.find('form').data('destination'));
    var $saveButton = $notesModal.find('input[type="submit"]');

    // Update the notes so that if the modal is re-opened the updated notes are shown
    $note.data('notes', noteText);
    // Ensure the correct icon is being shown to the user
    $note.find('i')
         .toggleClass('fas', noteText.length != 0)
         .toggleClass('far', noteText.length == 0);

    $saveButton.prop('disabled', false);
    $notesModal.modal('hide');
  });
});
