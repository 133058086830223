document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".js-maximum-decimal-places-input").forEach(element => {
    element.addEventListener("input", event => {
      const value = event.target.value;
      const float = parseFloat(value);

      const nonZero = float != 0;
      const containsADecimal = value.indexOf('.') != -1;

      if (nonZero && containsADecimal) {
        const maximumDecimalPlaces = 2;
        const numberOfDecimals = value.toString().split(".")[1].length;

        if (numberOfDecimals > maximumDecimalPlaces) {
          event.target.value = value.toString().slice(0, value.indexOf(".") + maximumDecimalPlaces + 1);
        }
      }
    })
  })
})
