$(function() {
  InterExchange.SimpleSelectOther = function(_, element) {
    const $element = $(element);
    $element.addClass('js-simple-select-other-initialized');
    const $input = $element.find('select, input[type=radio], input[type=checkbox]'),
      toggledElement = $element.data('selectingOtherTogglesElement');

    const toggleValue = $element.data('toggle-value') || 'Other';

    $input.on('change', function(event) {
      const selectedValue = $input.is('select') ? $input.find('option:selected').text() : event.currentTarget.value;
      var toggledElementShouldBeUpdated = false;
      var otherIsSelected = false;

      // For checkboxes, we only want to respond if the "Other" checkbox is checked or unchecked.
      if ($input.is('input[type=checkbox]')) {
        otherIsSelected = event.currentTarget.checked;
        toggledElementShouldBeUpdated = selectedValue === toggleValue;
      } else {
        // For radios and select there is only one possible value. If that is "Other" then we should
        // attempt to hide/show the toggled element.
        otherIsSelected = selectedValue === toggleValue;
        toggledElementShouldBeUpdated = true;
      }

      if (toggledElementShouldBeUpdated) {
        $(toggledElement)
          .toggleClass("d-none", !otherIsSelected)
          .toggle(otherIsSelected)
          .find('input, textarea').prop('disabled', !otherIsSelected);
      }
    });
  };

  const initialize = function() {
    $('*[data-selecting-other-toggles-element]:not(.js-simple-select-other-initialized)').each(InterExchange.SimpleSelectOther);
  }

  initialize();
  $(document).on('shown.bs.modal', initialize);
});
