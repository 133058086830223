// This should be able to handle any form toggling based on input values
//
// It has been used extensively in:
//    app/views/ap/participant_applications/childcare_work_histories/edit.html.erb
//    app/views/ap/participant_applications/childcare_work_histories/_experience_fields.html.erb
//
// You have multiple methods of using this with a simpleform select, radio, or checkbox
// $input = form.input(..., wrapper_html: { data: { selecting_toggles_elements: ['.targetElement'] } })
//
//
// <div class="toggle-scope">
//   <div class="targetElement" data-toggle-value="exampleValue">
//   <input type="radio" value="exampleValue" />
//
//   <div class="targetElement" data-toggle-value="exampleValue" data-toggle-key="example">
//   <input type="checkbox" data-toggle-example="exampleValue" />
//
//   <div class="targetElement" data-toggle-value="exampleValue" data-toggle-method="validationMethod">
// </div>
// var simpleSelectToggleMethods = { validationMethod: function($toggleScope) { return "exampleValue" } }
//
$(function() {
  InterExchange.SimpleSelectToggleElements = function(_, element) {
    const $element = $(element)
    const $toggleScope = $element.closest('.toggle-scope')
    const $input = $element.find('select, input[type=radio], input[type=checkbox]')

    if ($toggleScope.length == 0) {
      console.error("Could not find toggle scope element '.toggle-scope' for simple select toggle elements. Did you define a wrapping toggle-scope element?")
    }

    $input.on('change', function(event) {
      let currentValue = null
      let $dataTarget = null

      if($input.is('select')) {
        $dataTarget = $input.find('option:selected')
        currentValue = $dataTarget.text()
      } else if ($input.is('input[type=radio]')) {
        $dataTarget = $input
        currentValue = event.currentTarget.value
      } else if ($input.is('input[type=checkbox]')) {
        $dataTarget = $input
        currentValue = event.currentTarget.checked ? 'true' : 'false'
      }

      let targets = []
      $element.data('selectingTogglesElements').forEach(function(target) {
        let $target = $toggleScope.find(target)
        if ($target.length == 0) {
          console.error("Could not find target element '" + target + "' for simple select toggle elements. Do you need to add a . or # prefix to this selector?")
        } else {
          targets.push($target)
        }
      })

      targets.forEach(function($target) {
        let requiredValue = $target.data('toggle-value')

        if($dataTarget && $target.data('toggle-key')) {
          currentValue = $dataTarget.data($target.data('toggle-key'))
        }

        if($dataTarget && $target.data('toggle-method')) {
          let methodName = $target.data('toggle-method')
          /*global simpleSelectToggleMethods */
          currentValue = simpleSelectToggleMethods[methodName]($toggleScope)
        }

        // If the blank/placeholder option is selected, we want to hide the element regardless.
        if (currentValue == undefined) {
          $target.hide()
        } else {
          $target.toggle(requiredValue.toString() == currentValue.toString())
        }
      })
    })
  }

  const initializeSimpleSelectToggles = function() {
    $('*[data-selecting-toggles-elements]:not(.js-simple-select-toggle-elements-initialized)').each(InterExchange.SimpleSelectToggleElements)
  }

  window.initializeSimpleSelectToggles = initializeSimpleSelectToggles

  initializeSimpleSelectToggles()
})
