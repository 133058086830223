// This has been deprecated in favour of src/js/application/simple_select_toggle_elements.js
//
$(document).on('change', '.toggler :radio, .toggler :checkbox', function() {
  const toggleGroup = $(this).closest('.toggle-group');
  let val;
  let toggleOnValues;

  if (toggleGroup[0].dataset.toggleGroupOnValue) {
    val = this.value.toLowerCase();
    toggleOnValues = [toggleGroup[0].dataset.toggleGroupOnValue.toLowerCase()];
  }
  else if (toggleGroup[0].dataset.toggleGroupOnArray) {
    val = this.value;
    toggleOnValues = toggleGroup[0].dataset.toggleGroupOnArray.split(',');
  }
  else {
    val = this.value.toLowerCase();
    toggleOnValues = ['true', 'yes', '1', 'other'];
  }

  const toggleOn = toggleOnValues.indexOf(val) >= 0
  const toggledElement = toggleGroup[0].querySelector(".toggled")
  const untoggledElement = toggleGroup[0].querySelector(".untoggled")

  if (toggleOn) {
    if (toggledElement) {
      toggledElement.style.display = ""
      toggledElement.classList.remove("d-none")
      toggledElement.querySelectorAll("input, textarea").forEach(element => element.disabled = false)
    }

    if (untoggledElement) {
      untoggledElement.style.display = "none"
      untoggledElement.classList.add("d-none")
      untoggledElement.querySelectorAll("input, textarea").forEach(element => element.disabled = true)
    }
  }
  else {
    if (toggledElement) {
      toggledElement.style.display = "none"
      toggledElement.classList.add("d-none")
      toggledElement.querySelectorAll("input, textarea").forEach(element => element.disabled = true)
    }

    if (untoggledElement) {
      untoggledElement.style.display = ""
      untoggledElement.classList.remove("d-none")
      untoggledElement.querySelectorAll("input, textarea").forEach(element => element.disabled = false)
    }
  }
})

$(document).on('change', '.js-toggle-specific:radio', function() {
  const val = this.value.toLowerCase();
  const $toggleTarget = $($(this).data('toggle-target'));
  const toggleOnValues = ['true', 'yes', 'other'];
  const toggleOn = toggleOnValues.indexOf(val) >= 0;

  $toggleTarget.toggle(toggleOn).find('input, textarea').prop('disabled', !toggleOn);
});
