
import "bootstrap-datepicker/dist/js/bootstrap-datepicker"
import "bootstrap"
import "daterangepicker/daterangepicker";
import select2 from "select2/dist/js/select2"
import "core-js/stable"
import "regenerator-runtime/runtime"
import "trix"
import "@rails/actiontext"

// Load LiveReload if development
if (DEVELOPMENT) {
  const source = new EventSource("http://localhost:5678")
  console.log('⚡ Listening for reloads') // eslint-disable-line no-console
  source.onmessage = (event) => {
    if (event.data === "reload") {
      console.log('Reloading...') // eslint-disable-line no-console
      window.location.reload()
    }
  }
}

import "./jquery"
import "./interexchange"
import "./controllers/stimulus"

select2(window, $);

// Don't allow drag and drop file attachment
window.addEventListener("trix-file-accept", function(event) {
  event.preventDefault()
  console.warn("File attachment not supported!")
})

// The major difference between debouncing and throttling is that debounce calls a function when a
// user hasn't carried out an event in a specific amount of time, while throttle calls a function at
// intervals of a specified amount of time while the user is carrying out an event.
// This was replaced with Underscore's version of debounce, which includes proper context passing.
window.debounce = (func, wait = 250, immediate = false) => {
  var timeout, previous, args, result, context;

  var later = function() {
    var passed = new Date().getTime() - previous;
    if (wait > passed) {
      timeout = setTimeout(later, wait - passed);
    } else {
      timeout = null;
      if (!immediate) result = func.apply(context, args);
      if (!timeout) args = context = null;
    }
  };

  var debounced = function(..._args) {
    context = this;
    args = _args;
    previous = new Date().getTime();
    if (!timeout) {
      timeout = setTimeout(later, wait);
      if (immediate) result = func.apply(context, args);
    }
    return result;
  };

  debounced.cancel = function() {
    clearTimeout(timeout);
    timeout = args = context = null;
  };

  return debounced;
}

window.throttle = (callback, delay = 250) => {
  let throttleTimeout = null
  let storedEvent = null

  const throttledEventHandler = (event) => {
    storedEvent = event

    const shouldHandleEvent = !throttleTimeout

    if (shouldHandleEvent) {
      callback(storedEvent)

      storedEvent = null

      throttleTimeout = setTimeout(() => {
        throttleTimeout = null

        if (storedEvent) {
          throttledEventHandler(storedEvent)
        }
      }, delay)
    }
  }

  return throttledEventHandler;
}

import "./application/**/*.js";
import "./application-vendor/**/*.js";
import "./channels/**/*_channel.js";

Rails.start()
