/* globals BraintreeHostedFields: true */

class PaymentForm {  
  constructor($form, clientAuthorization, inputs, submitButton) {
    this.$form = $form;
    this.clientAuthorization = clientAuthorization;
    this.inputs = inputs;
    this.submitButton = submitButton;
  }

  initializeForm() {
    this._initializeBraintree();
    this._initializeFormHandler();
  }

  setIsLoading(isLoading) {
    this.submitButton.setIsLoading(isLoading);
  }

  _initializeBraintree() {
    this.braintreeHostedFields = new BraintreeHostedFields(this.clientAuthorization, this.inputs.number, this.inputs.expirationDate, this.inputs.cvv);
    this.braintreeHostedFields.onError(() => {
      this._showErrorPanel();
    });

    this.braintreeHostedFields.initializeBraintree(() => {
      this.submitButton.setIsEnabled(true);
    });
  }

  _initializeFormHandler() {
    // The Submit button must be disabled until the Braintree Hosted Fields have initialized.
    this.submitButton.setIsEnabled(false);

    this.$form.submit((event) => {
      event.preventDefault();

      // Any input that is not required but hasn't been interacted with will be set to be valid
      $.each(this.inputs, function(key, input) {
        input.validate();
      });

      if (this._isValid()) {
        this.setIsLoading(true);

        const cardholderName = this.inputs.cardholderName.getValue();
        this.braintreeHostedFields.getNonce(cardholderName, (nonce) => {
          // Populate a hidden field with the nonce and submit the form to our application
          $('#js-nonce').val(nonce);
          this.$form.off('submit').submit();
        });
      } else {
        this.submitButton.setIsEnabled(true);
      }
    });
  }

  _isValid() {
    const inputsAreValid = $.map(this.inputs, function(input) {
      return input.isValid;
    });

    // -1 means the array does not contain false
    return $.inArray(false, inputsAreValid) == -1;
  }

  _showErrorPanel() {
    $('#js-payment-error-container').removeClass('d-none');
    $('#js-payment-form-container').addClass('d-none');
  }
}

window.PaymentForm = PaymentForm
