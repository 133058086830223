class AsyncModal {
  constructor() {
    // This element is rendered on the page for a place to put the incoming modal
    this.modalContainerIdentifier = "#js-async-modal-container"

    // This is the identifier for the modal content that will be loaded
    this.modalIdentifier = ".js-async-modal"
    this.replaceableModalIdentifier = ".js-async-modal:not(.js-async-modal-retain)"
    this.permanentModalIdentifier = ".js-async-modal-retain"

    this.revertURLonModalClose = true

    this.populateNewModal = this.populateNewModal.bind(this)
  }

  modalElementForRetainedContent() {
    return(document.querySelector(`${ this.permanentModalIdentifier }[data-url='${ this.triggerElement.getAttribute("href") }']`))
  }

  retainContent() {
    return(this.triggerElement?.dataset?.asyncModalRetainContent == "true")
  }

  _load(location, updateUrl = true) {
    if (JSON.parse(updateUrl) === true) {
      history.pushState(null, '', location)
    }

    if (this.retainContent() && this.modalElementForRetainedContent()) {
      $(this.modalElementForRetainedContent()).modal("show")
      return(Promise.resolve())
    }
    else {
      return(
        $.get(location)
        .done(this.populateNewModal)
        .fail((jqXHR, textStatus, errorThrown) => {
          if (jqXHR.status == 401) {
            this.handleAuthorizationError(jqXHR)
          }
        })
      )
    }
  }

  populateNewModal(data) {
    const modal = document.querySelector(this.modalIdentifier)

    // If there was an existing modal open we close it. This can happen if we open a modal
    // from within another modal.
    if (modal && modal.classList.contains('show')) {
      this.revertURLonModalClose = false
      // The fade class is initially used on a modal to ensure that it animates in when it is shown.
      // If we are replacing a modal we don't want it to animate away so we remove the class.
      $(modal).removeClass('fade').modal('hide')

      // We also don't want the new window to animate in, so we should remove the new class from
      // that modal, too.
      $(this.modalContainerIdentifier).html(data).find(this.modalIdentifier).removeClass('fade').modal()
    } else {
      this.removeEphemeralModals()

      $(this.modalContainerIdentifier).append(data).find(this.replaceableModalIdentifier).modal()

      if (this.retainContent()) {
        // From now on the _load function will not make a remote call to get this modal's content
        // The modal will stay around and be simply loaded from the DOM
        document.querySelector(this.modalIdentifier).classList.add("js-async-modal-retain")
      }
    }
  }

  removeEphemeralModals() {
    document.querySelector(this.modalContainerIdentifier).
      querySelectorAll(this.replaceableModalIdentifier).
      forEach(element => element.remove())
  }

  _replaceModalContent(newContent) {
    $(this.modalIdentifier).removeClass('fade').modal('hide')
    $(this.modalContainerIdentifier).html(newContent).find(this.modalIdentifier).removeClass('fade').modal()
  }

  handleAuthorizationError(xhr) {
    // This is generally handled by the access_denied method in ApplicationController
    //
    // At this point we have received a HTTP status of 401 and we expect the response to have a
    // path to redirect to
    window.location = JSON.parse(xhr.responseText)["redirect_to"]
  }

  asyncModalTriggerForUrl(url) {
    return(document.querySelector(`a[data-async-modal][href='${ url }']`))
  }

  setup() {
    // If the user visits the URL of a modal window directly (EG: /cc/on_review/20817/confirming) the
    // controller will identify that the request is not XHR and will load a default page (EG: /cc/matching/on_review/).
    // The default page should usually be the page on which you'd usually click a button to open
    // the modal displayed by the original given URL.
    //
    // The URL of the modal will be populated in the markup, so that when the page loads we
    // can open that URL in a new modal window.
    const modalURL = document.querySelector(this.modalContainerIdentifier).dataset.modalUrl
    if (modalURL) {
      this.triggerElement = this.asyncModalTriggerForUrl(modalURL)
      this._load(modalURL)
    }

    // There is currently a bug in Rails UJS that enables buttons before the redirect or action is
    // complete.
    // If you look in the UJS code the only thing that ajax:complete.rails does is re-enable the
    // elements in the form and we don't need that.
    // For the time being we would need to perform work in ajax:error and ajax:success if we need
    // to re-enable buttons on error
    //
    // https://github.com/rails/rails/pull/31441
    $(document).undelegate($.rails.formSubmitSelector, 'ajax:complete.rails')

    $(document).on('hidden.bs.modal', this.modalIdentifier, this.modalHidden.bind(this))
    $(document).on('show.bs.modal', this.modalIdentifier, this.modalShow.bind(this))
    $(document).on('shown.bs.modal', this.modalIdentifier, this.modalShown.bind(this))
    $(document).on('click', 'a[data-async-modal], button[data-async-modal]', this.loadModalContent.bind(this))
    $(document).on('ajax:success', 'form[data-async-modal]', this.ajaxSuccess.bind(this))
    $(document).on('ajax:error', 'form[data-async-modal]', this.ajaxError.bind(this))
    $(document).on('ajax:complete', 'form[data-async-modal]', this.ajaxComplete.bind(this))
  }

  ajaxError(event) {
    const xhr = event.detail[2]
    // Rails returns a 422 in cases where the object being created/updated has validation errors.
    // In this case, we are expecting the response to contain markup for the modal window that has
    // validation errors on the form.
    //
    // The other case is an access denied message (status: 401)
    //
    if (xhr.status == 422) {
      this.revertURLonModalClose = false
      const response = xhr.responseText
      if (response) this._replaceModalContent(response)
    }
    else if (xhr.status == 401) {
      this.handleAuthorizationError(xhr)
    }
    else if (xhr.status >= 500) {
      alert("Our server has experienced an unexpected error. Our team has been notified and will do their best to resolve this issue. Please contact us if you need support.")
    }
  }

  ajaxSuccess(event) {
    // Handle when a form is a submitted within the modal:
    //
    // On success if we're provided a Location in the header we'll want to redirect the user. If the
    // form has been configured with data-async-modal-replace-content=true, we should instead expect
    // the response to contain markup to replace the modal window with.
    //
    // On failure the controller should render the form with the errors highlighted, which we'll
    // display in the modal.
    //
    const form = event.currentTarget
    const submitButton = form.querySelector('input[type="submit"]')
    // This is to let our complete callback know that we should be ensuring the submit button is disabled
    submitButton.dataset.shouldBeDisabled = true

    const xhr = event.detail[2]
    const url = xhr.getResponseHeader('Location')
    if (url) {
      const newUrl = new URL(url, window.location)
      // When setting the location with a URL that has an anchor and a pathname that matches the
      // pathname of the window.location the page will not reload.
      // In the case of the AsyncModal, the page will scroll to that anchor but will not close the
      // modal and reload the UI. In this case we need to force a reload of the page. When doing so
      // the user will see the page scroll to the anchor before the page will reload.
      //
      const reloadPage = newUrl.hash !== "" && newUrl.pathname == window.location.pathname

      window.location = newUrl

      if (reloadPage) {
        window.location.reload()
      }
    } else {
      if (form.dataset.asyncModalReplaceContent == "true") {
        const response = xhr.responseText
        if (response != undefined) {
          this._replaceModalContent(response)
        }
      }
    }
  }

  ajaxComplete(event) {
    // The complete callbacks follow both the success and error callbacks: https://api.jquery.com/Ajax_Events/
    //
    // The complete handler will not be called when the request fails with a 422 because we replace
    // the markup in the 'ajax:error' handler above. The complete handler will run for requests
    // that fails with >=500 as that handler shows an alert and doesn't change the markup.
    //
    // If we are using the @rails/ujs attribute "disable_with" to disable the submit button, the submit
    // button will be disabled from when it is clicked until the "ajax:complete.rails" event is fired.
    //
    // The typical workflow of an async modal on success is to then redirect the user to a new page.
    // This means that in the time between beginning the redirect and the page refreshing, the submit
    // button will be active, which allows the user to spam the button.
    //
    // In our success handler above, we set a data attribute to let us know that the submit button
    // should still be disabled. If that is set, we ensure the submit button is disabled here until
    // the page has successfully redirected. We also want to retain the disable_with message, which
    // will have been swizzled away by the disable_with functionality.
    //
    const form = event.currentTarget
    const submitButton = form.querySelector('input[type="submit"]')

    if (submitButton.dataset.shouldBeDisabled) {
      submitButton.disabled = true

      if (submitButton.dataset.disableWith) {
        submitButton.value = submitButton.dataset.disableWith
      }
    }
  }

  modalHidden() {
    // When we close a modal we want to set the URL to the original URL
    if (this.revertURLonModalClose) {
      const originalPath = document.querySelector(this.modalContainerIdentifier).dataset.originalPath
      if (originalPath) {
        history.pushState(null, '', originalPath)
      }
    } else {
      this.revertURLonModalClose = true
    }
  }

  modalShow() {
    // Hide other modals
    $(`.modal.show:not(${ this.modalIdentifier }`).modal("hide")
  }

  modalShown() {
    // Initialize any components once the modal has been opened
    $('.js-select2').each(function(_, element) {
      $(element).select2({
        width: 'element',
        tags: true,
        dropdownParent: $('.modal-body')
      })
    })

    window.ConfirmableSubmitButton.initialize()
    window.Tooltip.init()
  }

  loadModalContent(event) {
    event.preventDefault()
    // Clicking a link with "data-async-modal" should cause a modal to open with the contents of the
    // provided URL.
    //
    // Async modals can have "data-async-modal" links in them. In this case we should replace the already
    // open modal with the content of the link's URL.
    //
    const linkOrButton = event.currentTarget
    const updateUrl = linkOrButton.dataset.updateUrl

    this.triggerElement = linkOrButton

    // Disable the link so the user can't spam it.
    linkOrButton.classList.add('disabled')
    this._load(linkOrButton.getAttribute('href'), updateUrl).then(() => linkOrButton.classList.remove('disabled'))

    return false
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new AsyncModal().setup()
})
