// Select all selectable items
$(document).on('change', '.js-select-all', (event) => {
  const checked = event.target.checked;
  const table = $(event.target).closest('table');

  table.find('.js-select-all-selectable:not(:disabled)').prop('checked', checked).trigger('change');
});

// Take user to page to sign the agreements
$(document).on('click', '#js-sign-and-submit', (event) => {
  const targetUrl = event.currentTarget.dataset.targetUrl;
  const checkedElements = $.makeArray($('.js-employer-application-agreements .js-select-all-selectable:checked'));
  const ids = checkedElements.map(element => element.value);
  const queryString = $.param({ agreement_ids: ids });

  window.location.replace(`${ targetUrl }?${ queryString }`);
});

$(document).on('change', '.js-employer-application-agreements .js-select-all-selectable', () => {
  const submitButton = document.getElementById('js-sign-and-submit');
  const selectAllElement = $('.js-select-all');

  // Enable button if any checkboxes are checked
  if ($('.js-employer-application-agreements .js-select-all-selectable:checked').length > 0) {
    submitButton.removeAttribute('disabled');
  }
  else {
    submitButton.setAttribute('disabled', true);
  }

  // If all possible boxes checked then check the select all checkbox
  if ($('.js-employer-application-agreements .js-select-all-selectable:not(:disabled):not(:checked)').length > 0) {
    selectAllElement.prop('checked', false);
  }
  else {
    selectAllElement.prop('checked', true);
  }
});

// Submitting Agreements
$(document).on('submit', '.bulk-submit', (event) => {
  event.preventDefault();
  event.stopPropagation();

  const form = event.target;
  const submitButton = form.querySelectorAll('.btn.btn-primary')[0];
  const fullName = form.employer_application_agreement_form_full_name.value;
  const title = form.employer_application_agreement_form_title.value;
  const agreed = form.employer_application_agreement_form_agreed.checked;
  const agreementFinder = '.employer-application-agreements-sidebar .employer-application-agreement';

  if (fullName.length == 0 || title.length == 0 || !agreed) {
    alert('Please fill in the Agreement form before submitting.');

    // Bootstrap uses setTimeout to disable the button after it is clicked
    // https://github.com/twbs/bootstrap/issues/6242
    // Their timeout is set at 0 so anything > 0 should be enough
    setTimeout(() => { submitButton.removeAttribute('disabled'); }, 100);

    return false;
  }

  // For elements in the sidebar submit the form and show success/failure
  let results = [];
  const deferreds = $(agreementFinder).map((index, element) => {
    const sidebarElement = new EmployerApplicationAgreementSidebarElement(element);
    const signingService = new EmployerApplicationAgreementSigner({ url: element.dataset.url, templateForm: form });

    sidebarElement.start();

    return(
      signingService.sign().
      done(() => {
        sidebarElement.success();
        results.push('success');
      }).
      catch((response) => {
        submitButton.removeAttribute('disabled');

        let message = response.statusText;
        if (Object.prototype.hasOwnProperty.call(response, 'responseJSON') && response.responseJSON.message.length > 0) {
          message = response.responseJSON.message;
        }

        sidebarElement.failed(message);
        results.push('failed');
      })
    );
  }).toArray();

  // Would love to use the results of the deferreds but there are umpteen posts on SO
  // and other sites showing this doesn't work as expected. $.when will however at
  // least wait until they've all resolved which is necessary before checking
  // the results
  $.when(...deferreds).then(() => {
    const allSuccessful = results.every((result) => result == 'success');

    if (allSuccessful) {
      window.location = submitButton.dataset.successUrl;
    }
    else {
      window.Intercom('showNewMessage', 'I had trouble signing multiple agreements. Can I have some help?');
    }
  });
});

// Try to keep DOM interactions with the sidebar element here
class EmployerApplicationAgreementSidebarElement {
  constructor(element) {
    this.element = $(element);
    this.errorContentElement = this.element.find('.error-content');
    this.successCssClass = 'list-group-item-success';
    this.failedCssClass = 'list-group-item-danger';
    this.startCssClass = 'list-group-item-actionable-started';
  }

  reset() {
    this.errorContentElement.text('');
    this.element.removeClass(`${ this.successCssClass } ${ this.failedCssClass } ${ this.startCssClass }`);
  }
  start() {
    this.reset();
    this.element.addClass(this.startCssClass);
  }

  success() {
    this.reset();
    this.element.addClass(this.successCssClass);
  }

  failed(message) {
    this.reset();
    this.element.addClass(this.failedCssClass);
    this.errorContentElement.text(`Error: ${ message }`);
  }
}

// Try to keep submission logic here
class EmployerApplicationAgreementSigner {
  constructor({url, templateForm}) {
    this.url = url;
    this.form = templateForm.cloneNode(true); // deep clone to get elements
  }

  sign() {
    this.form.action = this.url;

    return $.ajax({
      url: this.url,
      type: 'PUT',
      dataType: 'json',
      data: $(this.form).serialize()
    });
  }
}
