$(document).ready(function() {

  InterExchange.DocumentSelect = function(_, element) {
    var $element = $(element),
        $select = $element.find('select'),
        $target = $($element.data('target')),
        $otherDocument = $target.find('.other-document'),
        $instructions = $target.find('.document-instructions'),
        $requiredFieldSet = $target.find("fieldset.document_required");

    $select.on('change', function() {
      $otherDocument.hide().find('input').prop('disabled', true);

      if ('' === this.value) {
        resetFields();
      } else if ('Other' === this.value) {
        $otherDocument.show().find('input').prop('disabled', false);
        resetFields();
      } else {
        const document = currentDocument(this.value);
        var instructions = '';
        var required = null;

        if (document) {
          instructions = document.instructions;
          required = document.required;
        }

        if (instructions && instructions.length > 0) {
          setDocumentInstructions(instructions);
          $instructions.show();
        } else {
          $instructions.hide();
        }

        // When creating a Document, its required attribute will be set by the corresponding Document Option.
        // Some Departments allow specific user types to override the Document option and specify whether
        // a Document should be required or not. If the option exists for the user, we first default the
        // required field to the configured value of the selected Document Option.
        if (required !== null && requiredFieldPresent()) {
          setRequiredRadioOptions(required, !required);
          $requiredFieldSet.show();
        }
      }
    });

    function currentDocument(documentName) {
      if ('Other' === documentName) {
        return null;
      }

      return InterExchange.DepartmentDocuments.filter(function(item) {
        return item.name == documentName;
      })[0];
    }

    function setDocumentInstructions(instructions) {
      $instructions.find('.card-body').html(instructions);
    }

    function requiredFieldPresent() {
      return $requiredFieldSet.length > 0
    }

    function setRequiredRadioOptions(yes, no) {
      if (requiredFieldPresent()) {
        var $requiredRadioInputs = $requiredFieldSet.find("input:radio[name='document[required]']")
        $requiredRadioInputs.filter("[value='true']").prop("checked", yes);
        $requiredRadioInputs.filter("[value='false']").prop("checked", no);
      } else {
        return;
      }
    }

    function resetFields() {
      $instructions.hide();
      $requiredFieldSet.hide();

      setDocumentInstructions("");
      setRequiredRadioOptions(false, false);
    }
  };

  $('*[data-behavior="InterExchange.DocumentSelect"]').each(InterExchange.DocumentSelect);
});
