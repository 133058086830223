$(document).ready(function() {
  const defaultDatePickerAttributes = {
    autoclose: true,
    clearBtn: true,
    format: 'M dd yyyy'
  };

  // We do not initialize these datepickers in the global initialization (datepicker.js) because the
  // changeDate event handler interferes with the handler we define below.
  $('input.datepicker:not(.datepicker-initialized)').datepicker(defaultDatePickerAttributes);

  $('.js-date-range-filter').each(function (_, element) {
    const $datepickers = $(element).find('input.datepicker');
    const $fromDatepicker = $($datepickers[0]);
    const $toDatepicker = $($datepickers[1]);

    const ensureToDateIsOnOrAfterFromDate = function() {
      // Note - 'getDate' returns null if there is a date set but it is invalid (ie: before the startDate)
      const fromDate = $fromDatepicker.datepicker('getDate');
      $toDatepicker.datepicker('setStartDate', fromDate);
      const toDate = $toDatepicker.datepicker('getDate');

      if (toDate == undefined) {
        // This will set the default date on the datepicker when you open it to the given date. There is
        // no way to change the defaultViewDate that I can see from the documentation, it looks like
        // we must destroy the datepicker to facilitate this.
        $toDatepicker.datepicker('destroy');
        $toDatepicker.datepicker($.extend(defaultDatePickerAttributes, {
          defaultViewDate: fromDate,
          startDate: fromDate
        }));

        $toDatepicker.datepicker('setDate', '');
        $toDatepicker.datepicker('hide'); // 'setDate' will focus the calendar
        $toDatepicker.blur();
      }
      // We need this datepicker to be focussed so that if the user presses "Enter" the form will submit.
      $fromDatepicker.focus();
    };

    $fromDatepicker.on('changeDate', ensureToDateIsOnOrAfterFromDate);
  });
});
