class ConfirmButtonGroup {
  constructor($container) {
    this.$container = $container
    this.$confirmCancelButtonGroup = $container.find('.js-confirm-btn-group')
    this.$cancelButton = this.$confirmCancelButtonGroup.find('.js-cancel-button')
    this.$confirmButton = this.$confirmCancelButtonGroup.find('.js-confirm-button')
    this.confirmButtonDisabled = false

    this.$linksButtonGroup = $container.find('.js-confirmable-actions')
    this.$links = this.$linksButtonGroup.find('a:not([disabled]):not(.disabled):not(.js-no-confirm)')
  }

  static initialize() {
    $('.js-confirm-button-group:not(.js-confirm-button-group-initialized)').each(function (_, element) {
      const confirmButtonGroup = new ConfirmButtonGroup($(element))
      confirmButtonGroup.attach()
    });
  }

  attach() {
    // When we click "Cancel" we want to show the original options
    this.$cancelButton.click((event) => {
      event.preventDefault()
      this.$confirmCancelButtonGroup.hide()
      this.$linksButtonGroup.show()
    });

    // When we click "Confirm" we should disable the button so it can't be spammed
    this.$confirmButton.click((event) => {
      if (this.confirmButtonDisabled) {
        event.preventDefault()
      }
      this.$confirmButton.addClass('disabled', true)
      this.confirmButtonDisabled = true
    });

    // Ensure that if any of the links in the group have a data-confirm-method set then all links do
    // otherwise the data-method will get out of sync.
    const numberOflinksWithConfirmMethod = this.$links.filter('[data-confirm-method]').length;
    if (numberOflinksWithConfirmMethod != 0 && numberOflinksWithConfirmMethod != this.$links.length) {
      console.error(this.$linksButtonGroup)
      console.error('Some links are missing a data-confirm-method. If some links have a custom confirm method and others don\'t the value on the button can get out of sync')
    }

    // When we click a link in the button-group we'll want to show the Confirm/Cancel buttons.
    this.$links.each((_, anchor) => {
      $(anchor).click((event) => {
        const element = event.currentTarget

        if (!element.classList.contains('js-confirm-button-not-confirmable')) {
          event.preventDefault()

          this.$confirmCancelButtonGroup.show()
          this.$linksButtonGroup.hide()

          // When we click "Confirm" we should perform the current action. The "Confirm" button should have
          // data-method set on it so Rails' jquery-ujs library will submit the link with the correct HTTP method.
          this.$confirmButton.attr('href', event.currentTarget.dataset.confirmUrl)

          // If this link has specified that it needs a different HTTP method we will update it.
          const confirmMethod = element.dataset.confirmMethod
          if (confirmMethod) {
            // Use attr() not data() so the DOM is updated.
            this.$confirmButton.attr('data-method', confirmMethod)
          }

          if (element.dataset.confirmWithAsyncModal) {
            this.$confirmButton.attr("data-async-modal", true)
          }
        }
      })
    })

    this.$container[0].classList.add("js-confirm-button-group-initialized")
  }
}

$(document).ready(function() {
  ConfirmButtonGroup.initialize()
})

window.ConfirmButtonGroup = ConfirmButtonGroup
