$(document).ready(function() {
  const $putOnReviewModal = $('#put-on-review-modal')
  if ($putOnReviewModal.length > 0) {
    const onReviewModal = new PutOnReviewModal($putOnReviewModal)

    // This shouldn't be necessary but I cannot get the default functionality working
    $putOnReviewModal.click(function (event) {
      // event.currentTarget will be the modal (the element that the event is tied to).
      // event.target will be the element that was clicked.
      //
      // This check ensures that we have clicked on the overlay but not the contents of the modal itself.
      if (event.target == event.currentTarget) {
        $putOnReviewModal.modal('hide')
      }
    })

    // When a user clicks on the "Put on Review" button the correct modal is opened and the title of the
    // modal is set from data stored on the button.
    $(document).on('click', '[data-toggle=\'put-on-review-modal\']', (event) => {
      // This will prevent the browser from jumping to the top of the page
      event.preventDefault()

      const $button = $(event.currentTarget)
      const participantGroupId = $button.data('participant-group-id')
      const modalTitle = $button.data('modal-title')

      onReviewModal.setParticipantData(modalTitle, participantGroupId)
      onReviewModal.show()
    })
  }

})

class PutOnReviewModal {
  constructor($element) {
    this.$putOnReviewModal = $element
    this.$searchForm = this.$putOnReviewModal.find('form')
    this.$onlyShowMyHostsCheckbox = this.$searchForm.find('.js-only-my-hosts')
    this.$hostsWithInterviewsCheckbox = this.$searchForm.find('.js-hosts-with-interviews')
    this.$searchInput = this.$searchForm.find('#search')
    this.$searchButton = this.$searchForm.find('button')
    this.$searchResults = this.$searchForm.find('.search-result-content')
    this.$noSearchText = this.$searchForm.find('.no-search-content')
    this.$loadingSearchText = this.$searchForm.find('.loading-search-content')

    this._initializeModalOpenedHandler()
    this._initializeOnlyShowMyHostsClickHandler()
    this._initializeOnReviewButtonHandler()
    this._initializeSearchButtonHandler()
  }

  // Ensure the "Search" button is disabled while the search is being performed so users don't spam it
  setIsSearching(isSearching) {
    this.$onlyShowMyHostsCheckbox.prop('disabled', isSearching)
    this.$hostsWithInterviewsCheckbox.prop('disabled', isSearching)
    this.$searchButton.prop('disabled', isSearching)
    this.$searchInput.prop('disabled', isSearching)
    // We hide the search placeholder text once a search has been made. When the modal is reset we'll
    // show it again and empty the search results.
    this.$noSearchText.hide()
    this.$loadingSearchText.toggle(isSearching)

    // A UX requirement for this feature is that the search field is in focus when the search finishes
    if (!isSearching) {
      this.$searchInput.focus()
    }
  }

  setParticipantData(modalTitle, participantGroupId) {
    // We must remember which group was being shown so we know if we need to reset the interface. Ideally
    // we want to keep the search results for the same group if you open/close the modal accidentally.
    //
    // Otherwise, we should clear the previous search.
    if (this.participantGroupId != participantGroupId) {
      this.$putOnReviewModal.find('.modal-title').text(modalTitle)
      // These are hidden fields - both are used to find Employers
      this.$putOnReviewModal.find('#participant_group_id').val(participantGroupId)

      this.$searchResults.hide().text('')
      this.$noSearchText.show()
      this.$loadingSearchText.hide()
      this.$searchInput.val('')
      this.$onlyShowMyHostsCheckbox.prop('checked', true)
      this.$hostsWithInterviewsCheckbox.prop('checked', false)

      // We perform an initial search when the modal is first opened. For Employees, they're likely to
      // have fewer than 5 Employers show up.
      //
      // Similarly, Staff will have the "Only show my hosts" checkbox on by default, and so will see
      // a much more cut-down view of Employers.
      this._searchForMatchingEmployers()
    }

    this.participantGroupId = participantGroupId
  }

  show() {
    this.$putOnReviewModal.modal()
  }

  _getSearchText() {
    return this.$searchInput.val()
  }

  _initializeModalOpenedHandler() {
    const onReviewModal = this

    // A UX requirement for this feature is that the search field is in focus when the modal opens
    this.$putOnReviewModal.on('shown.bs.modal', () => {
      onReviewModal.$searchInput.focus()
    })
  }

  _initializeOnlyShowMyHostsClickHandler() {
    const onReviewModal = this

    this.$onlyShowMyHostsCheckbox.change(() => {
      onReviewModal._searchForMatchingEmployers()
    })

    if (this.$hostsWithInterviewsCheckbox.length) {
      this.$hostsWithInterviewsCheckbox?.change(() => {
        onReviewModal._searchForMatchingEmployers()
      })
    }
  }

  // When a user clicks "Put on Review" for a specific Employer, we disable all the "Put on Review" buttons
  // so they can't spam them and get an error flash message.
  _initializeOnReviewButtonHandler() {
    const onReviewModal = this
    this.$searchResults.on('click', '.put-on-review-button', (event) => {
      const confirmMessage = $(event.currentTarget).data('confirm-message')
      onReviewModal.$searchResults.find('.put-on-review-button').addClass('disabled')

      if (!confirm(confirmMessage)) {
        event.preventDefault()
        onReviewModal.$searchResults.find('.put-on-review-button').removeClass('disabled')
        return false
      }
    })
  }

  // When a user clicks on "Search" to search for a list of Employers inside the "Put on Review" modal
  _initializeSearchButtonHandler() {
    const onReviewModal = this
    this.$searchButton.click((event) => {
      onReviewModal._searchButtonWasClicked(event, onReviewModal)
    })
  }

  _searchButtonWasClicked(event, onReviewModal) {
    event.preventDefault()
    onReviewModal._searchForMatchingEmployers()
    // Prevent the form from submitting
    return false
  }

  _searchForMatchingEmployers() {
    // We need to retrieve the form data before the form inputs are disabled as they will not show up then
    const url = new URL(this.$searchForm.prop('action'), window.location.origin).pathname
    const formData = new FormData(this.$searchForm[0])
    const fetchUrl = `${ url }?${ new URLSearchParams(formData) }`

    this.setIsSearching(true)
    this.$searchResults.hide()

    fetch(fetchUrl).
      then((response) => {
        if (response.ok) {
          return(response.text())
        }
        else {
          throw new Error()
        }
      }).
      then((body) => {
        this.$searchResults[0].innerHTML = body
        this.setIsSearching(false)
        this.$searchResults.fadeIn()
      }).
      catch((_error) => {
        this.$searchResults.show().text('There was an error performing this search. Please refresh your browser and try again.')
      })
  }
}
