$(document).ready(function() {
  $('.photo-input-container').each(function (_, element) {
    const $element = $(element);
    const $photoFilepickInputContainer = $element.find('.js-photo-filepicker-input');
    const $filePickerButton = $element.find('.js-filepicker-input');
    const $filePickerInput = $element.find(`#${ $filePickerButton.attr('input_id') }`);
    const $dropdown = $element.find('.js-photo-input-dropdown');
    const $dropdownButton = $dropdown.find('.photo-dropdown-button');
    const $dropdownSpinnerOverlay = $dropdown.find('.photo-dropdown-spinner-overlay');
    const $dropdownImage = $dropdownButton.find('.js-photo-dropdown-button-image');
    const $dropdownOptionsLabel = $dropdownButton.find('.photo-dropdown-options-label');
    const $dropdownUploadLink = $dropdown.find('.js-photo-dropdown-upload-link');
    const $dropdownRemoveLink = $dropdown.find('.js-photo-dropdown-remove-link');
    const $form = $element.closest('form');
    const $formSubmitButton = $form.find('input[type="submit"]');

    const uploadEnablesSubmitButton = $element.data('upload-enables-submit-button');

    $dropdownImage.on('load', function() {
      $dropdownSpinnerOverlay.hide();
      $dropdownOptionsLabel.show();
      $dropdownButton.removeClass('disabled');
    });

    $dropdownUploadLink.on('click', function(event) {
      event.preventDefault();
      $photoFilepickInputContainer.find('button').click();
    });

    $dropdownRemoveLink.on('click', function(event) {
      event.preventDefault();
      $filePickerInput.val('');
      $dropdown.hide();
      $photoFilepickInputContainer.show();

      if (uploadEnablesSubmitButton) {
        $formSubmitButton.prop('disabled', true);
      }
    });
  })
});
