/* globals google: true */

class USTerritories {
  static codes = ["AS", "PR", "VI", "GU",  "MP"];
}

class Place {
  static convertFromGooglePlace(googlePlace) {
    const converted = {};
    // US Unincorporated Territories are treated as states by App, but Google treats them as their own countries.
    // This code checks for countries that are unincorporated territories and is later used to be transformed
    // into states for the purposes of addresses to simplify forms
    const convertedCountry = USTerritories.codes.find((code) => code === googlePlace.get('country'));

    converted['line_1'] = [googlePlace.get('street_number'), googlePlace.get('route')].filter(Boolean).join(' ');
    converted['line_2'] = googlePlace.get('subpremise');
    converted['city'] = [googlePlace.get('locality'), googlePlace.get('sublocality')].filter(Boolean)[0];
    converted['state'] = convertedCountry ?? googlePlace.get('administrative_area_level_1');
    converted['postal_code'] = googlePlace.get('postal_code');

    return(converted);
  }
}

class GooglePlace {
  constructor(place) {
    this.place = place;
  }

  flattened() {
    if (this._flattened) {
      return(this._flattened);
    }
    else {
      const flattened = {};
      const addressComponents = this.place.address_components;

      if (addressComponents) {
        for (var i = 0; i < addressComponents.length; i++) {
          const component = addressComponents[i];

          for (var j = 0; j < component['types'].length; j++) {
            flattened[component['types'][j]] = component['short_name'];
          }
        }
      }

      this._flattened = flattened;

      return flattened;
    }
  }

  get(key) {
    return(this.flattened()[key]);
  }
}

class GooglePlaceInputManager {
  constructor() {
    this.inputs = [];
  }

  setupInputs() {
    document.querySelectorAll('.google-place-input').forEach((element) => {
      this.inputs.push(new GooglePlaceInput(element));
    });
  }

  disableGooglePlacesFeatures() {
    this.inputs.forEach((input) => input.disableGooglePlacesFeatures());
  }
}

class GooglePlaceInput {
  constructor(element) {
    this.element = element;
    // We can optionally pass in a country ISO3166 code via the `data-scoped-country` attribute on the input,
    // to scope the addresses to that country.
    // Default is the US.
    // The Google Place input uses ISO3166 for its country codes:
    //   https://developers.google.com/maps/documentation/javascript/places-autocomplete#add-autocomplete
    //
    this.country = this.element.dataset.scopedCountry?.toLowerCase() || 'us';

    this.autocomplete = new google.maps.places.Autocomplete(element);
    this.autocomplete.setFields(['address_components']);
    this.autocomplete.setComponentRestrictions({'country': [this.country].concat(USTerritories.codes)});
    this.autocomplete.addListener('place_changed', this.fillInAddress.bind(this));

    google.maps.event.addDomListener(this.element, 'keydown', (event) => {
      if (event.keyCode === 13) { event.preventDefault(); }
    });

    google.maps.event.addDomListener(this.element, 'focus', () => {
      // A hack to prevent Chrome autofill from putting itself over the Google Places API
      // drop down. The API sets autocomplete=off which does not work for Chrome
      // https://stackoverflow.com/a/30976223
      this.element.autocomplete = 'new-password';
    });
  }

  fillInAddress() {
    const googlePlace = new GooglePlace(this.getPlace());
    const convertedPlace = Place.convertFromGooglePlace(googlePlace);
    const placeToFormMap = JSON.parse(this.element.dataset.googlePlaceMap);
    const options = placeToFormMap.options;
    delete(placeToFormMap.options)

    Object.keys(placeToFormMap).forEach((elementId) => {
      if (placeToFormMap[elementId]) {
        let element
        const placeKey = placeToFormMap[elementId];
        const val = convertedPlace[placeKey] || '';

        if (options && options.contain_to_element) {
          element = $(this.element).closest(options.contain_to_element).find(`:input[id$=_${ elementId }]`)[0]
        }
        else {
          element = document.getElementById(elementId);
        }
        element.value = val;
        element.dispatchEvent(new Event('change'));
      }
    }, this);
  }

  getPlace() {
    return this.autocomplete.getPlace();
  }

  disableGooglePlacesFeatures() {
    this.element.disabled = false;
    this.element.placeholder = '';
    this.element.style = '';
    google.maps.event.clearInstanceListeners(this.element);
  }
}

window.initGooglePlaceAutoComplete = function() {  
  window.googlePlaceInputManager = new GooglePlaceInputManager();
  window.googlePlaceInputManager.setupInputs();

  // Support new elements added by nested form
  $(document).on('nested:fieldAdded', function(event) {
    window.googlePlaceInputManager = new GooglePlaceInputManager();
    window.googlePlaceInputManager.setupInputs();
  });
}

// Handles an auth failure with Google API
// The default behaviour is to disable the field, add an error and this icon in the top left corner
// of the input field. We remove all these changes and let the field operate normally
//
window.gm_authFailure = function() {  
  window.googlePlaceInputManager.disableGooglePlacesFeatures();
}
