class ParticipantGroupSubmitButtonGroup {
  constructor($container) {
    this.$confirmableSubmitButtonGroup = $container.find('.js-confirmable-submit-buttons');
    this.$actionLinks = this.$confirmableSubmitButtonGroup.find('a');
    this.$cancelButtons = $container.find('.js-cancel-button');
    this.$confirmButtons = $container.find('.js-confirm-button');
  }

  attach() {
    // When we click "Cancel" we want to show the original options
    this.$cancelButtons.click((event) => {
      event.preventDefault();
      $(event.currentTarget.closest('.btn-group')).hide();
      this.$confirmableSubmitButtonGroup.show();
    });

    // When we click "Confirm" we want to disable all the submittable buttons to prevent spamming them
    this.$confirmButtons.click(() => {
      this.$cancelButtons.addClass('disabled', true);
    });

    // When we click a link in the button-group we'll want to show the Confirm/Cancel buttons.
    // The Confirm button will be an actual form submit.
    this.$actionLinks.each((_, anchor) => {
      $(anchor).click((event) => {
        event.preventDefault();

        this.$confirmableSubmitButtonGroup.hide();
        $(anchor.dataset.target).show();
      });
    });
  }
}

$(document).ready(function() {
  $('.js-participant-group-submit-button-group').each(function (_, element) {
    const submitButtonGroup = new ParticipantGroupSubmitButtonGroup($(element));
    submitButtonGroup.attach();
  });
});
