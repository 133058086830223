import consumer from "./consumer"

class WtCohortCopier {
  _status
  _statusText
  _statusIcon
  _alert
  _targetCohortJobListingsLink
  _targetCohortEmployerEnrollmentsLink

  constructor(element) {
    this.element = element

    this._status = this.element.querySelector(".js-wt-cohort-copier-status")
    this._statusText = this.element.querySelector(".js-wt-cohort-copier-status span")
    this._statusIcon = this.element.querySelector(".js-wt-cohort-copier-status i")
    this._alert = this.element.querySelector(".alert")
    this._targetCohortJobListingsLink = this.element.querySelector(".js-wt-cohort-copier-target-cohort .job-listings-link")
    this._targetCohortEmployerEnrollmentsLink = this.element.querySelector(".js-wt-cohort-copier-target-cohort .employer-enrollments-link")
  }

  id() {
    return this.element.dataset.id
  }

  status() {
    return this.element.dataset.status
  }

  updateStatus(data) {
    this.element.dataset.status = data.status

    this._status.className = this._status.className.replace(/text-.*$/, `text-${data.status}`)
    this._statusIcon.className = this._statusIcon.className.replace(/fa-.*$/, `fa-${data.icon}`)
    this._statusText.innerHTML = data.status

    this._targetCohortJobListingsLink.innerHTML = this._targetCohortJobListingsLink.innerHTML.replace(/(\d+)/, data.target_cohort_job_listings_count)
    this._targetCohortEmployerEnrollmentsLink.innerHTML = this._targetCohortEmployerEnrollmentsLink.innerHTML.replace(/(\d+)/, data.target_cohort_employer_enrollments_count)

    if(data.icon_spin) {
      this._statusIcon.classList.add("fa-spin")
    } else {
      this._statusIcon.classList.remove("fa-spin")
    }

    if(this.status() == "error") {
      this._alert.innerHTML = data.error
      this._alert.classList.remove("d-none")
    }
  }
}

var wtCohortCopierSelector = document.querySelector(".js-wt-cohort-copier")

if(wtCohortCopierSelector) {
  var wtCohortCopier = new WtCohortCopier(wtCohortCopierSelector)

  consumer.subscriptions.create(
    {
      channel: "WtCohortCopierChannel",
      id: wtCohortCopier.id()
    },
    {
      received(data) {
        if (data.id == wtCohortCopier.id()) {
          wtCohortCopier.updateStatus(data)
        }
      }
    }
  )
}
