$(document).ready(function() {
  $('.js-sevis-reference-panel').each(function (_, element) {
    new SevisReferenceCard($(element))
  })

  $('.js-visa-appointment-card').each(function (_, element) {
    new VisaAppointmentCard($(element))
  })
})

$(document).on('ajax:success', '.js-new-visa-appointment-modal', (event) => {
  const content = event.detail[0].body.innerHTML
  const sevisReferencePanelId = $.parseHTML(content)[0].id
  const $panel = $(`#${sevisReferencePanelId}`)
  const $modal = $('.modal.show')
  const $content = $(content)

  $modal.modal('hide')
  $panel.replaceWith($content)
  new SevisReferenceCard($content)
})

class SevisReferenceCard {
  constructor($element) {
    this.$visaAppointmentDate = $element.find('.js-visa-appointment-date')
    this.$visaAppointmentStatus = $element.find('.js-visa-appointment-status')
    this.$visaAppointmentWarning = $element.find('.js-visa-appointment-warning')
    this.visaAppointmentStatusChanger = new VisaAppointmentStatusChanger(this, $element.find('.js-visa-appointment-status-changer'))
    this.visaAppointmentForm = new SevisReferenceVisaAppointmentForm(this, $element.find('.js-visa-appointment-form'))
  }

  updateStatus(status) {
    this.$visaAppointmentStatus.find('strong').text(status)
  }
}

class SevisReferenceVisaAppointmentForm {
  constructor(sevisReferenceCard, $element) {
    this.sevisReferenceCard = sevisReferenceCard
    this.$element = $element
    this.createVisaAppointmentUrl = $element.data('create-visa-appointment-url')
    this.$dateInput = $element.find('input[name="date"]')
    this.$submitButton = $element.find('input[type="submit"]')

    this._initializeSubmitButtonClickEvent()
  }

  createVisaAppointment(date) {
    if (date.length > 0) {
      $.ajax({
        data: { visa_appointment: { date: date } },
        dataType: 'json',
        url: this.createVisaAppointmentUrl,
        type: 'POST',
        beforeSend: () => {
          this.setSubmitButtonEnabled(false)
        },
        complete: () => {
          this.setSubmitButtonEnabled(true)
        },
        success: (data) => {
          const visaAppointmentDate = new Date(date)
          const currentDate = new Date()

          this.$element.hide(); // Hide the SevisReferenceVisaAppointmentForm

          this.sevisReferenceCard.$visaAppointmentDate.find('strong').text(data.visa_appointment.date)

          // Update the Visa Appointment URL with the newly created VisaAppointment ID,
          // then show the StatusChangerForm.
          this.sevisReferenceCard.visaAppointmentStatusChanger.updateUpdateUrl(data.visa_appointment.id)

          if (visaAppointmentDate > currentDate) {
            this.sevisReferenceCard.$visaAppointmentWarning.show()
          } else {
            this.sevisReferenceCard.visaAppointmentStatusChanger.$element.show()
          }
        },
        error: () => {
          alert('A Visa Appointment could not be created')
        }
      })
    } else {
      alert('Please make sure to enter a date')
      this.$dateInput.focus()
    }
  }

  setSubmitButtonEnabled(isEnabled) {
    this.$submitButton.prop('disabled', !isEnabled)
  }

  _initializeSubmitButtonClickEvent() {
    this.$submitButton.click(() => {
      this.createVisaAppointment(this.$dateInput.val())
    })
  }
}

class VisaAppointmentCard {
  constructor($element) {
    this.$deniedButton = $element.find('.js-visa-appointment-denied-button')
    this.$cardFooter = $element.find('.card-footer')

    this.$statusLabelRow = $element.find('.js-status-label')
    this.$statusLabel = this.$statusLabelRow.find('strong')
    this.visaAppointmentStatusChanger = new VisaAppointmentStatusChanger(this, $element.find('.js-visa-appointment-status-changer'))
  }

  updateStatus(status) {
    const statusLabelClass = `badge-${this._getLabelClassForStatus(status)}`

    this.$statusLabel.addClass(statusLabelClass)
    this.$statusLabel.text(status)
    this.$statusLabelRow.show()
    this.$cardFooter.hide()
  }

  _getLabelClassForStatus(status) {
    switch(status) {
      case 'Issued':
        return 'success'
      case 'Reapplying':
        return 'warning'
      case 'Denied':
        return 'danger'
      default:
        return 'default'
    }
  }
}

class VisaAppointmentStatusChanger {
  constructor(visaAppointmentContainer, $element) {
    this.issuedStatus = 'issued'
    this.deniedStatus = 'denied'
    this.reapplyingStatus = 'reapplying'

    this.visaAppointmentContainer = visaAppointmentContainer
    this.$element = $element
    this.$statusButtonGroup = $element.find('.js-status-btn-group')
    this.$confirmButtonGroup = $element.find('.js-confirm-btn-group')
    this.$issuedButton = this.$statusButtonGroup.find('.js-issued-btn')
    this.$deniedButton = this.$statusButtonGroup.find('.js-denied-btn')
    this.$confirmButton = this.$confirmButtonGroup.find('.js-confirm-btn')
    this.$cancelButton = this.$confirmButtonGroup.find('.js-cancel-btn')

    this.status = ''
    this.updateVisaAppointmentUrl = this.$confirmButton.data('url')
    this.visaAppointmentReapplyForm = new VisaAppointmentReapplyForm(this, $element.find('.js-visa-appointment-reapplying-form'))

    this._initializeIssuedButtonClickEvent()
    this._initializeDeniedButtonClickEvent()
    this._initializeConfirmButtonClickEvent()
    this._initializeCancelButtonClickEvent()
  }

  setConfirmButtonEnabled(isEnabled) {
    this.$confirmButton.prop('disabled', !isEnabled)
    this.$cancelButton.prop('disabled', !isEnabled)
  }

  updateUpdateUrl(visaAppointmentId) {
    this.updateVisaAppointmentUrl = this.updateVisaAppointmentUrl.replace('ID', visaAppointmentId)
  }

  updateVisaAppointmentStatus(status) {
    $.ajax({
      data: { visa_appointment: { status: status } },
      dataType: 'json',
      url: this.updateVisaAppointmentUrl,
      type: 'PUT',
      beforeSend: () => {
        this.setConfirmButtonEnabled(false)
      },
      complete: () => {
        this.setConfirmButtonEnabled(true)
      },
      success: (data) => {
        this.$element.hide(); // Hide the VisaAppointmentStatusChanger
        this.visaAppointmentContainer.updateStatus(data.friendly_status)
      },
      error: () => {
        alert('Visa Appointment status could not be updated')
      }
    })
  }

  _initializeCancelButtonClickEvent() {
    this.$cancelButton.click(() => {
      // We don't want the reapplying form to be visible if the user then clicks the Issued button
      this.visaAppointmentReapplyForm.$element.hide()
      this.$confirmButtonGroup.hide()
      this.$statusButtonGroup.show()
      this.status = ''
    })
  }

  _initializeConfirmButtonClickEvent() {
    this.$confirmButton.click(() => {
      // If the user has clicked the Denied button then we want to update the status based on
      // their selection of the reapply radio buttons.
      if (this.status == this.deniedStatus) {
        this.status = this.visaAppointmentReapplyForm.getStatusFromRadioButtons()
      }

      this.updateVisaAppointmentStatus(this.status)
    })
  }

  _initializeDeniedButtonClickEvent() {
    this.$deniedButton.click(() => {
      this.$statusButtonGroup.hide()

      // We only want to show the reapplying form when the Denied button is clicked
      this.visaAppointmentReapplyForm.$element.show()
      this.$confirmButtonGroup.show()
      this.status = this.deniedStatus
    })
  }

  _initializeIssuedButtonClickEvent() {
    this.$issuedButton.click(() => {
      this.$statusButtonGroup.hide()
      this.$confirmButtonGroup.show()
      this.status = this.issuedStatus
    })
  }
}

class VisaAppointmentReapplyForm {
  constructor(visaAppointmentStatusChanger, $element) {
    this.visaAppointmentStatusChanger = visaAppointmentStatusChanger
    this.$element = $element
  }

  getStatusFromRadioButtons() {
    const reapplyResponse = this.$element.find('input[name="reapplying"]:checked').val()

    if (reapplyResponse == 'yes') {
      return this.visaAppointmentStatusChanger.reapplyingStatus
    }

    return this.visaAppointmentStatusChanger.deniedStatus
  }
}
