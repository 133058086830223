$(document).ready(function() {
  $('.js-orientation-participant-complete').each(function (_, element) {
    new ParticipantOrientationParticipantComplete($(element));
  });
});

// This class is used for Participant views where the Participant's Orientation status can be marked
// as "Complete". Participants cannot mark a "Complete" Orientation as "Not Complete", only Staff
// can. See: ParticipantOrientationToggleComplete.
//
class ParticipantOrientationParticipantComplete {
  constructor($participantOrientationParticipantComplete) {
    this.$participantOrientationParticipantComplete = $participantOrientationParticipantComplete;
    this.$orientationCompleteInformationPanelElement = $(this.$participantOrientationParticipantComplete.data('orientation-complete-information-card-element'));
    this.$orientationCompleteQuizPanelElement = $(this.$participantOrientationParticipantComplete.data('orientation-complete-quiz-panel-element'));
    this.$loaderElement = $(this.$participantOrientationParticipantComplete.data('orientation-complete-loader-element'));
    this.url = this.$participantOrientationParticipantComplete.data('orientation-complete-path');

    $participantOrientationParticipantComplete.change(() => {
      this.updateCompleteStatus();
    });
  }

  updateCompleteStatus() {
    this.setIsLoading(true);

    $.ajax({
      method: 'PATCH',
      url: this.url,
      complete: () => {
        this.setIsLoading(false);
      },
      success: () => {
        this.$participantOrientationParticipantComplete.prop('disabled', true);
        this.$orientationCompleteInformationPanelElement.removeClass('bg-warning')
            .find('#js-orientation-completed-message').removeClass('d-none').end()
            .find('#js-orientation-not-completed-message').addClass('d-none');

        this.$orientationCompleteQuizPanelElement.removeClass('d-none');
      },
      error: () => {
        this.$participantOrientationParticipantComplete.prop('disabled', false);
        // Reverse the operation on the checkbox. Use prop here so the event is not fired again.
        this.$participantOrientationParticipantComplete.prop('checked', !this.$participantOrientationParticipantComplete.prop('checked'));
        alert('There was an issue changing the status of the orientation. Please refresh your browser and try again.');
      }
    });
  }

  setIsLoading(isLoading) {
    this.$participantOrientationParticipantComplete.toggleClass('d-none', isLoading);
    this.$loaderElement.toggleClass('d-none', !isLoading);
  }
}
