// The intention is to have a RemoteSubmitTriggerElement that when changed will use attributes
//  of its containing form to send the data.
//
// We intend for the RemoteSubmitTriggerElement to be contained in a form which will be contained by a
//  parent IndicatorElement (tr or div.row).
//
// The IndicatorElement will be used only to indicate failure/success.
//
// During the update the IndicatorElement should show some indication that something is happening.
// On success the IndicatorElement will have some transitionary effect to indicate that the update was successful.
// On error the IndicatorElement will permanently indicate that the update had an error.
//
// It is expected that this functionality will be used by the user directly when choosing a new
//  value in a drop down.
//
class RemoteSubmitTriggerElement {
  static initialize() {
    document.querySelectorAll(".js-remote-submit:not([data-remote-submit-initialized])").forEach(element => new this(element))
  }

  constructor(element) {
    this.element = element

    // We could complicate this by allowing the class to take a data attribute
    // but at this point I've chosen convention over configuration
    this.indicator = new IndicatorElement(this.element.closest("tr, div.row select"))

    this.form = new Form(this.element.closest("form"))

    this.element.dataset.originalValue = this.element.value

    this.element.addEventListener("change", this.handleChange.bind(this))

    this.sendUpdate = this.sendUpdate.bind(this)
    this.showSuccess = this.showSuccess.bind(this)
    this.showError = this.showError.bind(this)

    this.element.setAttribute("data-remote-submit-initialized", true)
  }

  handleChange() {
    // Promise handlers are bound to this in the constructor for ease of use
    this.showUpdateInProgress()
      .then(this.sendUpdate)
      .then(this.showSuccess)
      .catch((error) => {
        this.showError()
        console.error(error)
      })
  }

  sendUpdate() {
    return(this.form.submit())
  }

  showUpdateInProgress() {
    return(this.indicator.showUpdateInProgress())
  }

  showSuccess() {
    return(this.indicator.showSuccess())
  }

  showError() {
    this.element.value = this.element.dataset.originalValue
    return(this.indicator.showError())
  }
}

class IndicatorElement {
  constructor(element) {
    this.element = element
    this.element.classList.add("background-color-smooth-transition")
  }

  showUpdateInProgress() {
    this.reset()
    const promise = new Promise((resolve) => {
      this.element.classList.add("updating")
      resolve()
    });

    return(promise)
  }

  showError() {
    this.reset()
    const promise = new Promise((resolve) => {
      this.element.classList.add("error")
      resolve()
    });

    return(promise)
  }

  showSuccess() {
    const promise = new Promise((resolve) => {
      this.element.classList.remove("error", "updating")
      this.element.classList.add("success")

      setTimeout(() => { this.element.classList.remove("success") }, 1000)
      resolve()
    });

    return(promise)
  }

  reset() {
    this.element.classList.remove("error", "updating", "success")
  }
}

class Form {
  constructor(element) {
    this.element = element
    if (!this.initialized()) {
      this.promise = new Promise((resolve, reject) => {
        // This Promise is setup once to avoid duplicate event handlers being attached
        this.element.addEventListener("ajax:beforeSend", () => { this.disableElements() })
        this.element.addEventListener("ajax:success", () => { resolve() })
        this.element.addEventListener("ajax:error", (event) => { reject(event.detail) })
        this.element.addEventListener("ajax:complete", () => {
          this.enableElements()
          // This must occur last as subscribers of this event may change the DOM of the remote submit
          // select and we don't want to have race conditions between the default behaviour of this class
          // and subscriber classes.
          this.element.dispatchEvent(new Event("remote-submit-select-complete"))
        })
      })

      this.markInitialized()
    }
  }

  markInitialized() {
    this.element.setAttribute("data-remote-submit-initialized", true)
  }

  initialized() {
    return(this.element.hasAttribute("data-remote-submit-initialized"))
  }

  submit() {
    Rails.fire(this.element, "submit")
    return(this.promise)
  }

  disableElements() {
    Array.from(this.element.elements).forEach(element => element.disabled = true)
  }

  enableElements() {
    Array.from(this.element.elements).forEach(element => element.disabled = false)
  }
}

document.addEventListener("DOMContentLoaded", () => {
  RemoteSubmitTriggerElement.initialize()
})

window.RemoteSubmitTriggerElement = RemoteSubmitTriggerElement
