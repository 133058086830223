// This Button class extends a <button> by providing us the ability to set it to be loading or enabled/disabled.
//
// We leverage the Button JS provided by Bootstrap to swizzle the Loading messages:
//   * https://getbootstrap.com/docs/3.3/javascript/#buttons-stateful
//
class Button {  
  constructor($button) {
    this.$button = $button;
  }

  setIsEnabled(isEnabled) {
    this.$button.prop('disabled', !isEnabled)
      .toggleClass('btn-success', isEnabled)
      .toggleClass('btn-default', !isEnabled);
  }

  setIsLoading(isLoading) {
    this.$button.prop('disabled', isLoading);
    if (isLoading) {
      this.$button.button('loading');
    } else {
      this.$button.button('reset');
    }
  }
}

window.Button = Button
