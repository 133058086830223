$(document).ready(function() {
  InterExchange.CareerTrainingOccupationSelect = function(_, element) {
    var $element = $(element);
    var categories = $element.find('select.js-occupation-categories');

    categories.on('change', function(event) {
      var data = InterExchange.CareerTrainingOccupationSubjects,
          subjects = $element.find('select.js-occupation-subjects'),
          occupationId = parseInt(event.target.value);

      subjects.find('option').remove();
      subjects.append(new Option());

      if(occupationId) {
        data = data.filter(function(item) {
          return item.occupation_category_id === occupationId;
        });

        $.each(data, function(index, item) {
          subjects.append(new Option(item.name, item.id));
        });
      }
    });
  };

  $('*[data-behavior="InterExchange.CareerTrainingOccupationSelect"]').each(InterExchange.CareerTrainingOccupationSelect);
});
