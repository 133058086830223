class Tooltip {
  static init() {
    document.querySelectorAll('[data-toggle="tooltip"]').forEach((element) => {
      $(element).tooltip({
        // Pretty usual change that fixes many issues with tooltips
        //   - https://github.com/valor-software/ngx-bootstrap/issues/3075
        container: "body",
        // Allows the tooltip to display outside scrollport.
        // If for instance you had a page where the entire content only took up 1/2 the page
        //   then the tooltip would generally be limited to display inside that 1/2 page which
        //   if the tooltip is long can cause issues as the tooltip will jump to the top of the
        //   scrollparent (usually the <body> tag) and still not fit.
        //
        // Viewport - the area of screen the user can see
        // Scrollparent - the closest scrollable element, in the case above where the page is 1/2
        //   the Viewport height the scrollparent is likely to be <body>
        boundary: "viewport"
      })
    })
  }
}
document.addEventListener("DOMContentLoaded", () => { Tooltip.init() })

window.Tooltip = Tooltip
