$(document).ready(function() {
  $('[data-behavior="InterExchange.CcParticipantApplicationSkillsAndExperienceSecondarySkills"], .js-select2').each(function(_, element) {
    $(element).select2({
      tags: true,
      width: "100%",
    });
  });
});

/*
 * Hacky fix for a bug in select2 with jQuery 3.6.0's new nested-focus "protection"
 * Credit to: https://github.com/select2/select2/issues/5993#issuecomment-800927000
 *
 * see: https://github.com/select2/select2/issues/5993
 * see: https://github.com/jquery/jquery/issues/4382
 *
 * TODO: Recheck with the select2 GH issue and remove once this is fixed on their side
 */

$(document).on('select2:open', () => {
  document.querySelector('.select2-search__field').focus();
});
