class FieldCopier {
  constructor(element) {
    this.checkBox = document.getElementById(element.dataset.fieldCopierCheckbox);
    this.map = this._setupFieldMap($(element).find('[data-copy-field-to]'));
  }

  copyFields() {
    Object.keys(this.map).forEach((fromId) => {
      const toId = this.map[fromId];
      const sourceValue = document.getElementById(fromId).value;

      document.getElementById(toId).setAttribute('disabled', true);
      document.getElementById(toId).value = sourceValue;
    });
  }

  clearToFields() {
    Object.keys(this.map).forEach((fromId) => {
      const toId = this.map[fromId];

      document.getElementById(toId).removeAttribute('disabled');
      document.getElementById(toId).value = '';
    });
  }

  setupOnChangeForCopyFromFields() {
    Object.keys(this.map).forEach((fromId) => {
      document.getElementById(fromId).addEventListener('change', this.run.bind(this));
    });
  }

  setupCheckBoxClickHandler() {
    this.checkBox.addEventListener('click', this.run.bind(this));
  }

  run() {
    if (!this.checkBox.checked) {
      this.clearToFields();
    }
    else {
      this.copyFields();
    }
  }

  setup() {
    this.setupCheckBoxClickHandler();
    this.setupOnChangeForCopyFromFields();
  }

  _setupFieldMap(fields) {
    const map = {};

    fields.each(function() {
      map[this.id] = this.dataset.copyFieldTo;
    });

    return map;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  $('.js-field-copier').each(function() {
    new FieldCopier(this).setup();
  });
});
