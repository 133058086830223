import consumer from './consumer'

var notificationsChannel = Rails.notifications

notificationsChannel = consumer.subscriptions.create('NotificationsChannel', {
  initialized() {},

  connected() {
    this.install()
  },

  install() {
    $('.notification-export').each(function() {
      // Enable the Export button now that the cable is connected.
      Rails.enableElement(this)  

      // Do not enable the export button when the Ajax is complete.
      // This will be taken care of by the received method.
      const form = $(this).closest('form')
      form.on('ajax:complete', function(event) {
        event.preventDefault()
        return false
      })
    })
  },

  received(data) {
    if(data.action === "Pong") {
      return
    }

    if(data.action === "Remove") {
      const notification = $('#notifications').find(`[data-notification-id='${data.notification_id}']`)
      this.dismiss(notification)
    } else {
      $('.notification-export').each(function() {
        Rails.enableElement(this)  
      })

      this.alert(data.html)
    }
  },

  alert(notification_html) {
    // Insert notification into container
    $(notification_html).prependTo('#notifications .notifications-wrapper')
    $('.toast').toast('show')

    let channel = this
    $('.notification-error').on('hidden.bs.toast', function (event) {
      channel.dismiss(event.target)
    })

    // Show the notifications container if it isn't already
    if($('#notifications').hasClass('d-none')) {
      $('#notifications').addClass('d-block').removeClass('d-none')
    }
  },

  dismiss(element) {
    // Remove the notification element from the page
    $(element).remove()

    // Hide the notifications container if there are no more notifications
    if($('#notifications .notifications-wrapper .notification').length == 0) {
      $('#notifications').removeClass('d-block').addClass('d-none')
    }
  },

  ping() {
    return this.perform('ping');
  }
})

document.addEventListener("DOMContentLoaded", () => {
  // We need the connection between the client and the server to remain active otherwise Heroku will
  // start throwing H15 errors because the connection has gone idle.
  // To keep the connection alive we send a ping to the server every 30 seconds and wait for the a response.
  //
  setInterval(() => {
    notificationsChannel.ping()
  }, 30000);
});
