import { DirectUpload } from "activestorage"

// Bind to normal file selection
document.addEventListener('change', (event) => {
  const input = event.target

  // Only run if the file input field has a place to direct upload to
  if (input.type == "file" && input.dataset.directUploadUrl) {
    Array.from(input.files).forEach(file => uploadFile(file, input))
    // you might clear the selected files from the input
    input.value = null
  }
})

const uploadFile = (file, input) => {
  // your form needs the file_field direct_upload: true, which
  //  provides data-direct-upload-url
  const url = input.dataset.directUploadUrl
  const upload = new DirectUpload(file, url)
  const form = input.closest("form")

  upload.create((error, blob) => {
    if (error) {
      // Handle the error
    } else {
      // Add an appropriately-named hidden input to the form with a
      //  value of blob.signed_id so that the blob ids will be
      //  transmitted in the normal upload flow
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("value", blob.signed_id);
      hiddenField.name = input.name
      form.appendChild(hiddenField)

      // Show preview
      const width  = input.dataset.width || 110
      const height = input.dataset.height || 110

      const previewImage = document.createElement("img")
      previewImage.src = blob.current_url
      previewImage.width = width
      previewImage.height = height

      const removeButton = document.createElement("a")
      removeButton.classList = "btn btn-danger js-active-storage-file-remove"
      removeButton.href = "#"
      removeButton.text = "Remove"

      const previewImageContainer = input.closest(".js-active-storage-image").querySelector(".js-file-picker-image-preview-container")
      previewImageContainer.empty()
      previewImageContainer.appendChild(previewImage)
      previewImageContainer.appendChild(removeButton)

      // Delete hidden field that would delete the upload
      const deleteFieldName = input.name.replace("[", "[delete_")
      const deleteField = form.querySelector(`[name='${ deleteFieldName }']`)
      if (deleteField) { form.removeChild(deleteField) }
    }
  })
}

addEventListener("click", event => {
  const button = event.target

  if (button.classList.contains("js-active-storage-file-remove")) {
    const form = button.closest("form")
    const input = button.closest(".js-active-storage-image").querySelector("[type=file]")
    form.querySelector(`[name='${ input.name }']`).value = ""

    // Add a hidden field to delete the logo on form submission
    const deleteImageHiddenElement = document.createElement("input")
    deleteImageHiddenElement.type = "hidden"
    // e.g. cooperator[logo] => cooperator[delete_logo]
    deleteImageHiddenElement.name = input.name.replace("[", "[delete_")
    form.appendChild(deleteImageHiddenElement)

    button.parentNode.empty()
  }
})
