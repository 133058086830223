class PaymentFormField {  
  constructor($formGroup, options) {
    options = options || {};
    this.$formGroup = $formGroup;
    this.$formControl = $formGroup.find('.form-control');
    this.$helpText = $formGroup.find('.text-danger');

    this.formGroupErrorClass = 'form-group-invalid';
    this.formGroupSuccessClass = 'form-group-valid';
    this.formControlErrorClass = 'is-invalid';
    this.formControlSuccessClass = 'is-valid';

    this.isBraintreeHostedField = options.braintreeHostedField == true;
    this.isRadio = $formGroup.find('input[type=radio]').length > 0;
    this.isValid = false;

    // Validations
    this.validators = [];
    this.required = options.required == true;

    this.getInput().on('change focusout', () => {
      this.validate();
    });
  }

  addValidator(validator) {
    this.validators.push(validator);
  }

  validate() {
    if (this.isBraintreeHostedField) {
      this.setIsValid(this.isValid);
    } else {
      this._validatePresenceOfField();

      $.each(this.validators, (i, validator) => {
        validator(this);
      });
    }
  }

  isPopulated() {
    return this.getValue().length > 0;
  }

  getInput() {
    return this.$formGroup.find('input, textarea');
  }

  getValue() {
    if (this.isRadio) {
      return this.getInput().filter(':checked').val() || '';
    } else {
      return this.getInput().val();
    }
  }

  resetValidState() {
    this.$formGroup.removeClass(this.formGroupSuccessClass)
                   .removeClass(this.formGroupErrorClass);
    this.$formControl.removeClass(this.formControlSuccessClass)
                     .removeClass(this.formControlErrorClass);
    this.$helpText.text('');
    this.isValid = false;
  }

  setIsValid(isValid) {
    if (isValid) {
      this.$formGroup.addClass(this.formGroupSuccessClass)
                     .removeClass(this.formGroupErrorClass);
      this.$formControl.addClass(this.formControlSuccessClass)
                       .removeClass(this.formControlErrorClass);
      this.$helpText.text('');
    } else {
      this.$formGroup.removeClass(this.formGroupSuccessClass)
                     .addClass(this.formGroupErrorClass);
      this.$formControl.removeClass(this.formControlSuccessClass)
                       .addClass(this.formControlErrorClass);
      this.$helpText.text('is invalid');
    }
    this.isValid = isValid;
  }

  setRequired(isRequired) {
    this.required = isRequired;
  }

  _validatePresenceOfField() {
    if (this.required) {
      this.setIsValid(this.isPopulated());
    } else {
      // Fields that aren't required are valid either way, but look valid when populated and show no
      // formatting when they aren't populated.
      if (this.isPopulated()) {
        this.setIsValid(true);
      } else {
        // In this case, if the field is not required and is empty we do not want it to show as either
        // valid or invalid. Under the hood, the field is valid only in the respect that it is not invalid.
        this.resetValidState();
        this.isValid = true;
      }
    }
  }
}

window.PaymentFormField = PaymentFormField
