// This file is intended to allow for batch actions to take place from an index page
//
// Currently there is one index that uses this file so as we add this pattern elsewhere I do
//   expect changes to come. I've attempted to make it as open as I could in the constraints
//   I had
//
// The classes break down as:
//
//   * BatchActionTrigger
//       The button that is enabled by a row being selected and upon clicking will load the modal
//
//   * BatchActionModal
//       This operates like a typical modal except that it has some placeholders to show how many
//         items have been selected.
//       A button in the modal will trigger the actions to take place.
//       The modal will have a form in its body that can be modified for each item to send the
//         update to a #update_from_batch action.
//
//   * BatchActionManager
//       This class manages the actual actions taking place and indicate on the rows success or failure
//       It runs the updates sequentially so that returned HTML is at least correct after the
//         last request
//       It uses a form from the modal that is modified for each item. It submits to an action
//         that will use Rails remote JS to inject updated HTML into the table. The reason to
//         house this in a specific action or controller is that a bulk action by its nature
//         will have multiple updates to do to be successful
//
//   * BatchActionSelectManager
//       This keeps track of the selected items
//
class BatchActionTrigger {
  constructor(element) {
    this.element = element

    this.element.addEventListener("click", this.showModal.bind(this))
  }

  setSelectionManager(selectionManager) {
    this.selectionManager = selectionManager
  }

  enable(shouldEnable) {
    this.element.disabled = !shouldEnable
  }

  showModal() {
    if (!this.modal) {
      this.modal = new BatchActionModal({
        element: document.getElementById("js-batch-update-modal"),
        trigger: this,
        selectionManager: this.selectionManager,
      })
    }

    this.modal.show()
  }
}

class BatchActionManager {
  constructor(trigger, selectionManager) {
    this.trigger = trigger
    this.selectionManager = selectionManager

    this.startBatchAction = this.startBatchAction.bind(this)
  }

  startBatchAction() {
    // Early exit if the form has no changes
    // This will likely have to change when forms get more complicated
    if (this.trigger.modal.formHasNotChanged()) {
      return
    }

    const selectedItems = this.trigger.selectionManager.selectedItems()

    // Disable modal buttons & form
    // For each selected row
    //   Submit the form in the modal
    //   Show errors/success
    // Enable modal button

    // Process updates sequentially
    // Accumulates success/failure for each update as true/false
    const tasks = selectedItems.map((item) => this.promiseForItem(item))

    this.trigger.modal.triggerInProgress()

    tasks.reduce((promiseChain, task) => {
      return(
        promiseChain.then(results => {
          task.start()
          return(task.then(result => [...results, result]))
        })
      )
    }, Promise.resolve([]))
      .then(results => {
        // results is an array of the return values from all updates
        // true = success, false = failure
        if (results.includes(false)) {
          // Update the error message with a count
          this.trigger.modal.triggerError(results.filter(value => value == false).length)
        }
        else {
          this.trigger.modal.triggerSuccess()
        }
      })
  }

  promiseForItem(item) {
    const form = this.trigger.modal.formForItem(item)

    const promise = new Promise((resolve) => {
      form.addEventListener("ajax:beforeSend", () => { item.showUpdateStart() })
      form.addEventListener("ajax:success", () => { item.showSuccess(); resolve(true) })
      form.addEventListener("ajax:error", () => { item.showError(); resolve(false) })
      form.addEventListener("ajax:complete", () => { item.triggerComplete(form) })

    })

    promise.start = () => Rails.fire(form, "submit")
    return(promise)
  }
}

class BatchActionModal {
  constructor({ element, trigger, selectionManager }) {
    this.element = element
    this.decoratedElement = $(element)
    this.trigger = trigger
    this.selectionManager = selectionManager
    this.selectAllElement = selectionManager.element
    this.formRequiresAllSelections = (this.element.dataset.formRequiresAllSelections == "true")

    this.form = this.element.querySelector(".modal-body form")
    this.startUpdateElement = element.querySelector(".js-batch-update-start")
    this.confirmUpdateElement = element.querySelector(".js-batch-update-confirm")

    this.errorElement = element.querySelector(".js-batch-update-failure-indicator")
    this.errorCountElement = this.errorElement.querySelector(".js-batch-update-failure-count")

    this.singularItemName = this.element.dataset.singularItemName
    this.pluralizedItemName = this.element.dataset.pluralizedItemName

    this.batchActionManager = new BatchActionManager(trigger, selectionManager)

    this.startUpdateElement.addEventListener("click", this.confirmBatchAction.bind(this))
    this.confirmUpdateElement.addEventListener("click", this.batchActionManager.startBatchAction)

    this.form.addEventListener("change", this.handleFormChangeEvents.bind(this))

    this.decoratedElement.on("hidden.bs.modal", this.handleModalClose.bind(this))
  }

  handleModalClose() {
    this.resetButtons()
  }

  handleFormChangeEvents() {
    this.resetButtons()

    if (this.formHasSelection()) {
      this.startUpdateElement.classList.remove("disabled")
    }
    else {
      this.startUpdateElement.classList.add("disabled")
    }
  }

  formHasSelection() {
    if (this.formRequiresAllSelections) {
      return(this.userSelectableFormElements().every(element => element.selectedIndex != 0))
    }
    else {
      return(this.userSelectableFormElements().some(element => element.selectedIndex != 0))
    }
  }

  // NOTE: this will need to be updated as we add more form elements
  userSelectableFormElements() {
    return(Array.from(this.form.elements).filter(element => element.tagName == "SELECT" || element.tagName == "INPUT"))
  }

  confirmBatchAction() {
    if (this.startUpdateElement.classList.contains("disabled")) { return }

    this.startUpdateElement.classList.add("d-none")

    // Bootstrap will round the corners of the first and last child in a button group
    // so we switch the buttons
    //
    this.startUpdateElement.parentNode.insertBefore(this.confirmUpdateElement, this.startUpdateElement)
    this.confirmUpdateElement.classList.remove("d-none")
  }

  show() {
    this.resetButtons()

    const numberSelected = this.trigger.selectionManager.numberSelected()

    this.updateCountElements().forEach(element => element.textContent = numberSelected)
    if (numberSelected > 1) {
      this.itemNameElements().forEach(element => element.textContent = this.pluralizedItemName)
    }
    else {
      this.itemNameElements().forEach(element => element.textContent = this.singularItemName)
    }

    this.errorElement.classList.add("d-none")
    this.form.reset()
    this.decoratedElement.modal("show")
  }

  resetButtons() {
    this.startUpdateElement.classList.remove("d-none")
    this.startUpdateElement.classList.add("disabled")

    // Bootstrap will round the corners of the first and last child in a button group
    // so we switch the buttons
    //
    this.startUpdateElement.parentNode.insertBefore(this.startUpdateElement, this.confirmUpdateElement)

    this.confirmUpdateElement.classList.add("d-none")
  }

  updateCountElements() {
    return(this.element.querySelectorAll(".js-batch-update-count"))
  }

  itemNameElements() {
    return(this.element.querySelectorAll(".js-batch-update-item-name"))
  }

  formHasNotChanged() {
    const changedSelects = Array.from(this.form.querySelectorAll("select"))
      .filter(select => select.selectedIndex != 0)

    const changedRadios = Array.from(this.form.querySelectorAll("input[type='radio']")).
      filter(element => element.checked)

    return((changedSelects.length == 0) && (changedRadios.length == 0))
  }

  formForItem(item) {
    const form = this.form.cloneNode(true)
    form.classList.add("d-none")

    // cloneNode cannot clone the selected option as the DOM is not changed, the browser stores
    //   it in its state. Currently we only use select tags, but others might needs this type of
    //   massaging, too
    const selects = form.querySelectorAll("select")
    selects.forEach((newSelect => {
      const oldSelect = this.form.querySelector(`[name="${ newSelect.name }"]`)

      newSelect.value = oldSelect.value

      // When the form is eventually added to the DOM it needs a unique ID
      newSelect.id += `_${ item.id() }`
    }).bind(this))

    if (form.dataset.jsBatchUrlReplace) {
      form.action = form.action.replace(form.dataset.jsBatchUrlReplace, item.id())
    }
    else {
      form.action += `/${ item.id() }`
    }

    if (form.dataset.action) {
      form.action += `/${ form.dataset.action }`
    }

    // Need to append to the DOM to submit via Rails remote
    // These are cleaned up in BatchActionSelectOne#triggerComplete()
    document.querySelector("body").appendChild(form)
    return(form)
  }

  triggerInProgress() {
    // Prevent all modal dismiss elements from working
    this.element.querySelectorAll("[data-dismiss='modal']").forEach(element => {
      element.addEventListener("click", this.stopModalClosure)
    })
    // Prevent modal close by clicking outside, unfortunately you can't set the keyboard
    //   property this way to prevent closing by escape
    this.decoratedElement.data('bs.modal')._config.backdrop = 'static'

    this.form.querySelectorAll("select").forEach(element => element.disabled = true)
    this.errorElement.classList.add("d-none")
    this.originalConfirmElementHTML = this.confirmUpdateElement.innerHTML
    this.confirmUpdateElement.disabled = true
    this.confirmUpdateElement.innerText = "Updating ..."
  }

  stopModalClosure(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  triggerSuccess() {
    this.updateHasFinished()
    this.decoratedElement.modal("hide")
    this.trigger.enable(false)
  }

  triggerError(errorCount) {
    this.updateHasFinished()

    if (errorCount > 1) {
      this.errorCountElement.innerText = `were ${ errorCount } errors`
    }
    else {
      this.errorCountElement.innerText = `was ${ errorCount } error`
    }
    this.errorElement.classList.remove("d-none")
  }

  updateHasFinished() {
    // Enable close button/s and re-enable click outside modal to close
    this.element.querySelectorAll("[data-dismiss='modal']").forEach(element => {
      element.removeEventListener("click", this.stopModalClosure)
    })
    this.decoratedElement.data('bs.modal')._config.backdrop = true

    this.form.querySelectorAll("select").forEach(element => element.disabled = false)
    this.confirmUpdateElement.disabled = false
    this.confirmUpdateElement.innerHTML = this.originalConfirmElementHTML

    this.selectionManager.resetSelectors()
    this.selectionManager.enableOrDisableTrigger()
    this.resetButtons()
    this.selectAllElement?.reset()
  }
}

export default class BatchActionSelectManager {
  constructor({ selectAllElement, batchActionTrigger, selectOneElements } = {}) {
    if (selectAllElement) {
      this.element = new SelectAllElement(selectAllElement, this)
    }

    this.batchActionTrigger = batchActionTrigger

    this.individualSelectors = Array.from(selectOneElements).map(element => new BatchActionSelectOne(element, this))
    this.batchActionManager = new BatchActionManager()

    this.batchActionTrigger.setSelectionManager(this)
  }

  enableOrDisableTrigger() {
    if (this.anySelected()) {
      this.batchActionTrigger.enable(true)
    }
    else {
      this.batchActionTrigger.enable(false)
    }
  }

  resetSelectors() {
    const selectOneElements = document.querySelectorAll(".js-batch-action-select-one:not(.js-batch-on-select-one-initialized)")
    this.individualSelectors = Array.from(selectOneElements).map(element => new BatchActionSelectOne(element, this))
  }

  individualClicked() {
    this.batchActionTrigger.enable(this.anySelected())

    if (!this.element) { return }

    if (this.allSelected()) {
      this.element.checked = true
    }
    else {
      this.element.checked = false
    }
  }

  anySelected() {
    return(this.individualSelectors.some(element => element.checked()))
  }

  allSelected() {
    return(this.individualSelectors.every(element => element.checked()))
  }

  selectedItems() {
    return(this.individualSelectors.filter(element => element.checked()))
  }

  numberSelected() {
    return(this.selectedItems().length)
  }
}

class BatchActionSelectOne {
  constructor(element, manager) {
    this.element = element
    this.manager = manager
    this.container = this.element.closest("tr, .card")

    // Prevents the checkbox "autocompleting" when you press a back button to get to the page
    // Without this if you checked a box and later navigated back to this page (e.g. press back button)
    // then the checkbox would set itself back to checked AFTER any page load events
    //
    // The trigger button would then stay disabled unless the user checked another box causing it
    // to be enabled
    this.element.setAttribute("autocomplete", "off")

    this.element.addEventListener("click", this.handleClick.bind(this))

    this.showSuccess = this.showSuccess.bind(this)
    this.showError = this.showError.bind(this)
    this.triggerComplete = this.triggerComplete.bind(this)

    this.element.classList.add("js-batch-action-select-one-initialized")
  }

  check(value) {
    this.element.checked = value
    this.container.classList.toggle("selected", this.element.checked)
  }

  handleClick() {
    this.container.classList.toggle("selected", this.element.checked)
    this.manager.individualClicked()
  }

  checked() {
    return(this.element.checked)
  }

  id() {
    return(this.element.value)
  }

  showSuccess() {
    this.container.classList.add("success")
    setTimeout(() => { this.container.classList.remove("success") }, 1000)
  }

  showError() {
    this.container.classList.add("error")
  }

  triggerComplete(submittedForm) {
    this.container.classList.remove("updating")
    submittedForm.parentNode.removeChild(submittedForm)
  }

  showUpdateStart() {
    this.container.classList.add("updating")
    this.container.classList.remove("error", "success")
  }
}

class SelectAllElement {
  constructor(element, manager) {
    this.element = element
    this.manager = manager

    // Prevents the checkbox "autocompleting" when you press a back button to get to the page
    // Without this if you checked a box and later navigated back to this page (e.g. press back button)
    // then the checkbox would set itself back to checked AFTER any page load events
    //
    // The trigger button would then stay disabled unless the user checked another box causing it
    // to be enabled
    this.element.setAttribute("autocomplete", "off")

    this.element.addEventListener("click", this.handleClick.bind(this))
  }

  reset() {
    if (this.element.nodeName == "BUTTON") {
      const toggleStrong = this.element.querySelector("strong")
      const icon = this.element.querySelector("i")

      toggleStrong.textContent = "Select All"
      icon.classList.remove("fa-square")
      icon.classList.add("fa-check-square")
    }
  }

  handleClick(event) {
    if (this.element.nodeName == "BUTTON") {
      const toggleStrong = this.element.querySelector("strong")
      const icon = this.element.querySelector("i")
      const checked = toggleStrong.textContent == "Select All"

      this.manager.individualSelectors.forEach(selector => selector.check(checked))

      if (checked) {
        toggleStrong.textContent = "Deselect All"
        icon.classList.remove("fa-square")
        icon.classList.add("fa-check-square")
      }
      else {
        toggleStrong.textContent = "Select All"
        icon.classList.remove("fa-check-square")
        icon.classList.add("fa-square")
      }
    } else {
      this.manager.individualSelectors.forEach(selector => selector.check(this.element.checked))
    }

    this.manager.batchActionTrigger.enable(this.manager.anySelected())
  }
}

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".js-batch-action-trigger")) {
    const batchActionTrigger = new BatchActionTrigger(document.querySelector(".js-batch-action-trigger"))

    const batchActionSelectManager = new BatchActionSelectManager({
      selectAllElement: document.querySelector(".js-batch-action-select-all"),
      selectOneElements: document.querySelectorAll(":enabled.js-batch-action-select-one"),
      batchActionTrigger: batchActionTrigger,
    })

    // Useful for debugging
    window.batchActionTrigger = batchActionTrigger
    window.batchActionSelectManager = batchActionSelectManager
  }
})
