// This has been deprecated in favour of src/js/application/simple_select_toggle_elements.js
//
// Intends to show/hide the element with .toggled class depending on whether the element with
// .toggler class has a selected value
//
const handleSelectChange = (element) => {
  const toggleGroup = element.closest(".toggle-group")
  const toggleValue = toggleGroup.dataset.toggleGroupOnValue

  if (!toggleValue) {
    console.error("You must specify data-toggle-group-on-value for " + element.id)
    return
  }

  const isToggled = element.value == toggleValue

  toggleGroup.querySelectorAll(".toggled").forEach(element => {
    element.classList.toggle("d-none", !isToggled)
  })
}

document.addEventListener("DOMContentLoaded", (event) => {
  if (event.target.tagName == "SELECT" && event.target.classList.contains("toggler")) {
    handleSelectChange(event.target)
  }
})

document.addEventListener("change", (event) => {
  if (event.target.tagName == "SELECT" && event.target.classList.contains("toggler")) {
    handleSelectChange(event.target)
  }
})

document.addEventListener("reset", (event) => {
  if (event.target.tagName == "FORM") {
    event.target.querySelectorAll("select.toggler").forEach(handleSelectChange)
  }
})
