// The purpose of this class is to do two things:
//
//   * Auto-link URLs as a user types them
//   * Auto-link URLs that are part of pasted content
//
// Also intended is that as the user deletes content a token between two spaces that used to
// be a URL would be unlinked when that content is no longer a URL.
//
// There are known issues that start to become difficult to remedy and we consider not worth
// the cost. For instance if a user starts to edit inside of an already linked token the link
// would not be updated.
//
// If the user were to delete a character inside the token the link would not be updated until
// a space were added after it.
//
// If the user were to press space inside the token they'd have two separate tokens both
// linked to the original URL.
//
// These are potentially dangerous situations where the user expects probably that the text/s
// are properly linked but they aren't to the URL they expect.
//
// For that reason we de-link any piece of content when the user edits it and then wait for the
// user to re-enter linkable content.
//
class TrixAutoLinker {
  constructor(element) {
    this.element = element
    this.editor = element.editor

    element.addEventListener("trix-paste", this.pasteHandler.bind(this))
    element.addEventListener("keyup", this.keyUpHandler.bind(this))
  }

  keyUpHandler(event) {
    switch(event.which) {
      case 32: // Space
        this.handleSpace()
        break
      case 8: // Backspace
        this.handleBackspace()
    }
  }

  handleSpace() {
    const rangeToPreviousSpace = this.rangeToPreviousSpace("space")

    if (rangeToPreviousSpace.isUrl()) {
      rangeToPreviousSpace.addLink()
    }
  }

  handleBackspace() {
    const rangeToPreviousSpace = this.rangeToPreviousSpace("backspace")

    if (rangeToPreviousSpace.shouldBeUnlinked()) {
      rangeToPreviousSpace.removeLink()
    }
  }

  rangeToPreviousSpace(lastKey) {
    const content = this.editor.getDocument().toString()
    const cursorPosition = this.editor.getPosition()
    let previousSpacePosition

    switch(lastKey) {
      case "space":
        previousSpacePosition = content.slice(0, cursorPosition - 1).lastIndexOf(" ")
        return(new TrixRange(this.editor, previousSpacePosition + 1, cursorPosition - 1))
      case "backspace":
        previousSpacePosition = content.slice(0, cursorPosition).lastIndexOf(" ")
        previousSpacePosition = previousSpacePosition > 0 ? previousSpacePosition : 0
        return(new TrixRange(this.editor, previousSpacePosition, cursorPosition))
    }
  }

  contentToNextSpace() {
    const content = this.editor.getDocument().toString()
    const cursorPosition = this.editor.getPosition()
    const nextSpacePosition = content.slice(0, content.length).indexOf(" ")

    return(content.slice(cursorPosition, nextSpacePosition))
  }

  pasteHandler(event) {
    if (event.paste.hasOwnProperty("html")) { return } // They pasted HTML

    this.element.contentEditable = false
    const content = event.paste.string

    this.editor.recordUndoEntry("Auto Link Paste")

    // This could probably be easier just as any type of enumeration but there could
    // be performance benefits to doing each token as a Promise.
    //
    // It really isn't that hard to handle as Promises either.
    //
    // A later optimisation might be to use web workers but the longest text we've ever had
    // was just short of 1,600 words. The average is currently about 80.
    //
    // We could also simply add a notice to the UI the work is underway on the text.
    //
    let rangeStart = event.paste.range[0]
    const promises = content.split(/\s/).map((word) => {
      const promise = new Promise((resolve) => {
        const range = new TrixRange(this.editor, rangeStart, rangeStart + word.length)

        if (range.isUrl()) {
          range.addLink()
        }

        resolve(true)
      })

      rangeStart = rangeStart + word.length + 1

      return(promise)
    })

    Promise.all(promises).
      then(() => {
        window.setTimeout(() => {
          this.editor.insertString(" ")
          this.element.contentEditable = true
          this.element.focus()
        }, 1)
      })
  }
}

class TrixRange {
  constructor(editor, startPosition, endPosition) {
    this.editor = editor
    this.startPosition = startPosition
    this.endPosition = endPosition
    this.content = this.editor.getDocument().toString()
    this.text = this.content.slice(this.startPosition, this.endPosition)
  }

  isUrl() {
    let testToken = this.content.slice(this.startPosition, this.endPosition)

    // There are some cases that the URL() constructor thinks are URLs that almost certainly are
    // not.
    //
    // e.g.
    //    * "bye." - ends with dot
    //    * "bye. " - ends with dot but is probably last word and then a new sentence
    //    * "bye.\n" - similar to above
    //
    if (!testToken.includes(".") || testToken.endsWith(".") || testToken.endsWith(". ") || testToken.endsWith(".\n")) { return false }

    // We may choose to not auto-link links without proper scheme but typically we have tried to
    // auto-link this content. It does lead to many problems like the above.
    //
    if (!testToken.startsWith("http://") && !testToken.startsWith("https://")) {
      testToken = `http://${ testToken }`
    }

    // For performance we skip some expensive work if there is no way the token is a link now.
    //
    if (testToken.startsWith("http://") || testToken.startsWith("https://")) {
      try {
        new URL(testToken)

        return(true)
      }
      catch(e) {
        return(false)
      }
    }
  }

  addLink() {
    const currentSelection = this.editor.getSelectedRange()

    this.editor.setSelectedRange([this.startPosition, this.endPosition])

    if (this.text.trim().startsWith("http://") || this.text.trim().startsWith("https://")) {
      this.editor.activateAttribute("href", this.text)
    }
    else {
      this.editor.activateAttribute("href", `http://${ this.text }`)
    }

    this.editor.setSelectedRange(currentSelection)
  }

  shouldBeUnlinked() {
    return(!this.isUrl())
  }

  removeLink() {
    const currentSelection = this.editor.getSelectedRange()

    this.editor.recordUndoEntry("Auto Link Removal")
    this.editor.setSelectedRange([this.startPosition, this.endPosition])
    this.editor.deactivateAttribute("href")
    this.editor.setSelectedRange(currentSelection)
  }
}

document.addEventListener("trix-initialize", (event) => new TrixAutoLinker(event.target))
