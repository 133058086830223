$(document).ready(function() {

  InterExchange.AdminProgramPaymentConfiguration = function(_, element) {
    const $radioButtonsWrapper = $(element);
    const $hintContainer = $('#js-payment-type-hint');

    $radioButtonsWrapper.find('input[type=radio]').on('click', function (event) {
      const paymentType = event.currentTarget.value;
      $hintContainer.find('div[data-payment-type]').toggleClass('d-none', true);
      $('div[data-payment-type=' + paymentType + ']').toggleClass('d-none', false);
    });
  };

  $('#js-program-payment-type').each(InterExchange.AdminProgramPaymentConfiguration);
});
