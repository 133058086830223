// HTML5 supports a download attribute on links however depending on what the server sends
// in its headers or if it is a request to a different domain then this attribute is ignored.
//
// Rather than using the server to stream the file we allow the client to download the file as
// a blob then we trigger a click to download that blob with the filename that was given
// in the download attribute
class LinkWithDownloadAttribute {
  constructor(link) {
    this.link = link
    this.url = this.link.href
    this.name = this.link.getAttribute("download")
  }

  download() {
    if (!this.url) {
      throw new Error("Could not find URL to download file from")
    }

    this.setFetching(true)
    fetch(this.url)
      .then(response => response.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = blobURL
        a.style.display = "none"
        a.download = this.name

        document.body.appendChild(a)
        a.click()

        this.setFetching(false);
      })
      .catch(() => console.error("There was an issue downloading the file"))
  }

  setFetching(isFetching) {
    if (isFetching) {
      this.link.style.pointerEvents = "none"
    }
    else {
      this.link.style.pointerEvents = "auto"
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  // Done using delegation to handle new or modified content on the page.
  // Concrete use case at the moment is loading a Check-in Photo modal that is modified
  // before rending
  document.addEventListener("click", (event) => {
    const link = event.target.tagName == "A" ? event.target : event.target.closest("a")

    // The download method adds a link that downloads the file with the given filename
    // Without checking for display none it creates a loop
    if (link && link.hasAttribute("download") && link.style.display != "none") {
      event.preventDefault()
      new LinkWithDownloadAttribute(link).download()
    }
  })
})
