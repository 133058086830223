document.addEventListener('DOMContentLoaded', () => {
  $(document).on('click', '.js-file-remove', (event) => {
    // Prevent the link from moving the user's viewport to the top of the page
    event.preventDefault();

    const linkElement = event.currentTarget;
    const previewElement = linkElement.closest('.preview');
    const imageElement = previewElement.querySelector('img');
    const form = linkElement.closest('form');
    const fieldToClear = form.querySelector(`[id$=${ linkElement.dataset.fieldClear }]`);

    imageElement.setAttribute('src', '');
    previewElement.style.display = 'none';
    fieldToClear.value = '';
  });
});
