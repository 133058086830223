document.addEventListener("click", (event) => {
  const selectorClass = "js-batch-action-select-one"
  const unhandledSelectors = "button, a, input, select"

  // Clicking this element is handled by the existing Batch Action code
  if (event.target.classList.contains("js-batch-action-select-one")) { return }

  // Clicking labels triggers a click on the matching element
  // Handling it would trigger another click click
  if (event.target.matches("label")) { return }

  // Clicking another button/link should not trigger it, they have their own duties
  if (event.target.matches(unhandledSelectors)) { return }

  // Clicking a tag inside these elements also will be handled by those elements
  if (event.target.closest(unhandledSelectors)) { return }

  const container = event.target.closest(".js-click-selects")

  if (container) {
    const element = container.querySelector(`.${ selectorClass }`)
    element.click()

    container.classList.toggle("selected", element.checked)
  }
})
