$(document).ready(() => {
  document.querySelectorAll(".form-group .btn-group.btn-group-toggle label").forEach((label) => {
    const radio = label.querySelector("input[type=radio]")

    if (radio) {
      radio.dataset.previous = radio.checked

      radio.addEventListener("click", (event) => {
        if (radio.dataset.previous == "true") {
          event.stopImmediatePropagation()
          radio.checked = false
          label.classList.remove("active")
        }

        document.querySelectorAll(`input[type=radio][name="${radio.name}"]`).
          forEach(elem => elem.dataset.previous = elem.checked)
      })
    }
  })
})
