$(document).ready(function() {
  $('[data-participant-group-search-results-url]').each(function (_, element) {
    new ParticipantGroupSearch($(element));
  });
});

class ParticipantGroupSearch {
  constructor($participantGroupSearchContainer) {
    this.$errorMessage = $participantGroupSearchContainer.find('.js-error-message');
    this.$loadingMessage = $participantGroupSearchContainer.find('.js-loading-message');
    this.$pendingMessage = $participantGroupSearchContainer.find('.js-pending-message');

    this.$membersList = $($participantGroupSearchContainer.data('participant-group-members-list'));
    this.$searchInput = $participantGroupSearchContainer.find('.js-search-input');
    this.currentRequest = null;
    let debounceTimeout = null;
    this.$searchInput.keyup((event) => {
      const searchTerm = event.currentTarget.value;
      clearTimeout(debounceTimeout);
      const searchFunction = () => {
        this._searchTermWasUpdated(searchTerm);
      };
      debounceTimeout = setTimeout(searchFunction, 500);
    });

    // Clicking the "Add" button on a Participant's card should move it from "Available Members" to
    // "Members" and enable the hidden input so their ID is submitted with the form's save action
    $participantGroupSearchContainer.on('click', '.js-add-button', (event) => {
      event.preventDefault();
      const $listGroupItem = $(event.currentTarget).closest('.list-group-item');
      // Move the Participant's card from "Available Members" to "Members"
      $listGroupItem.detach().appendTo(this.$membersList);
      // Ensure the Participant will be registered as a new member of the group.
      $listGroupItem.find('input').prop('disabled', false);
      // Ensure the Participant can be removed from the group.
      $listGroupItem.find('.js-add-button').addClass('d-none');
      $listGroupItem.find('.js-remove-button').removeClass('d-none');

      this._refreshSearch();
    });

    // Clicking the "Remove" button on a Participant's card should remove them from the "Members" area
    // and refresh the "Available Members" search so that if the removed Participant is a part of those
    // results then they show up.
    this.$membersList.on('click', '.js-remove-button', (event) => {
      event.preventDefault();
      $(event.currentTarget).closest('.list-group-item').detach();
      this._refreshSearch();
    });

    this.$resultsContainer = $participantGroupSearchContainer.find('.js-search-results');
    this.url = $participantGroupSearchContainer.data('participant-group-search-results-url');
  }

  _getParticipantApplicationIds() {
    return this.$membersList.find('input').map(function(_, element) { return element.value; }).get();
  }

  _refreshSearch() {
    this._searchTermWasUpdated(this.$searchInput.val());
  }

  _searchTermWasUpdated(searchTerm) {
    if (searchTerm.length >= 3) {
      this._searchForParticipantsMatching(searchTerm);
    } else {
      this.$pendingMessage.toggleClass('d-none', false);
      this.$resultsContainer.empty();
    }
  }

  _searchForParticipantsMatching(searchTerm) {
    this._setIsLoading(true);

    if (this.currentRequest != null) {
      this.currentRequest.abort();
    }

    this.currentRequest = $.ajax({
      method: 'GET',
      url: this.url,
      data: {
        search_term: searchTerm,
        participant_application_ids: this._getParticipantApplicationIds()
      },
      complete: () => {
        this._setIsLoading(false);
      },
      success: (data) => {
        this.$resultsContainer.html(data);
      },
      error: () => {
        this.$errorMessage.toggleClass('d-none', false);
      }
    });
  }

  _setIsLoading(isLoading) {
    if (isLoading) {
      this.$resultsContainer.empty();
    }
    this.$errorMessage.toggleClass('d-none', true);
    this.$loadingMessage.toggleClass('d-none', !isLoading);
    this.$pendingMessage.toggleClass('d-none', true);
  }
}
