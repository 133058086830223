$(document).ready(initWaProgramSelect());

function initWaProgramSelect() {
  window.InterExchange.WaProgramSelect = function(_, element) {
    const $element = $(element);
    const $select = $element.find('select');
    const $explanationContainer = $($element.data('explanation-container'));
    const explanations = $explanationContainer.data('explanations');

    $select.on('change', function(event) {
      var program = getProgram(event.currentTarget.value),
          explanation = '';

      if (program) {
        explanation = program.explanation;
      }

      $explanationContainer.html(explanation);
    });

    function getProgram(programId) {
      return explanations.find(function(program) {
        return program.id == parseInt(programId);
      });
    }
  };

  $('*[data-behavior="InterExchange.WaProgramSelect"]').each(InterExchange.WaProgramSelect);
}

$(document).on('.js-wa-participant-program-detail-modal shown.bs.modal', function() {
  initWaProgramSelect();
});
