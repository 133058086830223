$(document).ready(function() {
  InterExchange.WTUniversityCountry = function(_, element) {
  const $element = $(element);
  var $select = $element.find('select'),
      $arrivalHint = $($element.data('arrival-date-element')),
      $departureHint = $($element.data('departure-date-element')),
      maxMonths = $element.data('max-months');

  $select.on('change', function(event) {
    var dates = InterExchange.ProgramDates[event.target.value];

    if (dates) {
      var arrivalDate = dates[0], departureDate = dates[1];

      $arrivalHint.html('Your arrival date must be ' + arrivalDate + ' or later');
      $departureHint.html('Your last day of work must be on or before ' + departureDate + ' and cannot be more than ' + maxMonths + ' months after your U.S. Arrival Date');
    } else {
      $arrivalHint.html('Please select the date you will be arriving');
      $departureHint.html('Your last day of work cannot be more than ' + maxMonths + ' months after your U.S. Arrival Date');
    }
  });
};

  $('*[data-behavior="InterExchange.WTUniversityCountry"]').each(InterExchange.WTUniversityCountry);
});
