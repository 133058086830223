import { Controller } from "@hotwired/stimulus"

const form_inputs = [
    "employer_name",
    "employer_website",
    "employer_address_1",
    "employer_address_2",
    "employer_city",
    "employer_state",
    "employer_zip",
    "contact_first_name",
    "contact_last_name",
    "contact_email",
    "contact_phone",
];

const refresher_inputs = [
    "employer_name",
    "employer_website",
    "employer_address_1",
    "employer_address_2",
    "employer_city",
    "employer_state",
    "employer_zip",
];

export default class extends Controller {
    static targets = [
        "matchForm",
        "contactCreateRow",
        "contactRow",
        "employerTable",
        "contactTable",
        "confirmButton",
        "loadingSpinner",

        ...form_inputs
    ]

    initialize() {
        this.loadEmployers = window.debounce(this.loadEmployers, 1500);
    }

    // Fired upon controller connect, essentially initialisation.
    connect() {
        this.loadContacts({ params: { employerId: -1 } });

        this.updateAllTableEntries();
        this.toggleContactCreate();
        this.toggleMatchButton();
        this.toggleContactTable();
    }

    updateFromInput(event) {
        var targetName = this.getInputName(event.target.name);
        this.updateTableEntry(targetName);

        if (refresher_inputs.includes(targetName)) {
            this.loadingSpinnerTarget.hidden = false;
            this.loadEmployers();
        }
    }

    // Update a single table create row cell from a form input
    updateTableEntry(targetName) {
        var data = this.getFormData();
        var targetElement = this[targetName + 'Target'];
        targetElement.innerHTML = data[`ct_employer_lead[${targetName}]`];
    }

    // Update all table create row cells from the form inputs
    updateAllTableEntries() {
        form_inputs.forEach((input) => this.updateTableEntry(input));
    }

    getInputName(str) {
        var rx = /ct_employer_lead\[(.*)\]/g;
        var arr = rx.exec(str);
        return arr[1]; 
    }

    toggleContactTable() {
        var data = this.getFormData();
        var employerAction = data['ct_employer_lead[employer_action]'];

        if (employerAction) {
            this.contactTableTarget.hidden = false;
        } else {
            this.contactTableTarget.hidden = true;
        }
    }

    // Check and toggle whether the contact create row is present or not.
    // If a contact exists with the same email, we don't allow a new entry to be created.
    toggleContactCreate() {
        var currentEmail = this.contact_emailTarget.innerHTML;

        // If the rows already contain the exact email, hide the create row
        if (this.contactRowTargets.some((row) => row.innerText?.includes(currentEmail))) {
            this.contactCreateRowTarget.hidden = true;
        } else {
            this.contactCreateRowTarget.hidden = false;
        }
    }

    // Check and toggle whether the match button is active or not.
    toggleMatchButton() {
        var data = this.getFormData();
        var employerAction = data['ct_employer_lead[employer_action]'];
        var contactAction = data['ct_employer_lead[contact_action]'];
        var newTrainingPlanType = data['ct_employer_lead[new_training_plan_type]'];

        // Some choice needs to be selected for both tables to proceed.
        if (employerAction && contactAction) {
            this.confirmButtonTarget.disabled = false;
        } else {
            this.confirmButtonTarget.disabled = true;
        }

        if (employerAction === 'create') {
            this.confirmButtonTarget.value = "Confirm Creation";
            this.confirmButtonTarget.dataset["confirm"] = "Are you sure you want to create this Employer and Contact?";
        } else if (!newTrainingPlanType && contactAction !== "create") {
            this.confirmButtonTarget.value = "Confirm Lead";
            this.confirmButtonTarget.dataset["confirm"] = "This Lead will not create a Training Plan and you have selected to match Employer and Contact to existing entities. The only action that will be taken is to archive this Lead. Are you sure you want to continue?";
        } else {
            this.confirmButtonTarget.value = "Confirm Match";
            this.confirmButtonTarget.dataset["confirm"] = "Are you sure you want to match this Lead to the selected Employer and/or Contact?";
        }
        
    }

    loadEmployers() {
        var url = this.employerTableTarget.dataset.employersUrl;
        var formData = new FormData(this.matchFormTarget);

        formData.set('_method', 'POST');
        
        fetch(url, {
            method: 'POST',
            body: formData
        })
            .then(response => response.text())
            .then(html => this.employerTableTarget.innerHTML = html)
            .then(() => this.updateAllTableEntries())
            .then(() => this.toggleContactTable())
            .then(() => this.toggleContactCreate())
            .then(() => this.toggleMatchButton())
            .then(() => {
                this.loadingSpinnerTarget.hidden = true;
            });
    }

    // This should use TurboFrames, but it's somewhat incompatible with UJS which makes it too hard basket for this task
    loadContacts({ params: { employerId } }) {
        var url = this.contactTableTarget.dataset.contactsUrl.replace('-1', employerId);
        
        fetch(url)
            .then(response => response.text())
            .then(html => this.contactTableTarget.innerHTML = html)
            .then(() => this.updateAllTableEntries())
            .then(() => this.toggleContactTable())
            .then(() => this.toggleContactCreate())
            .then(() => this.toggleMatchButton());
    }

    getFormData() {
        var form = this.matchFormTarget;
        return Object.fromEntries(new FormData(form).entries())
    }
}
