class VimeoVideo {
  constructor(video, container) {
    this.video = video;
    this.videoSrc = container.src;
  }

  play() {
    this._sendMessage('play');
  }

  pause() {
    this._sendMessage('pause');
  }

  _sendMessage(message) {
    this.video.postMessage({ 'method': message }, this.videoSrc);
  }
}

window.VimeoEmbedController = class VimeoEmbedController {  
  constructor(iFrame) {
    this.iFrame = iFrame;
    this.video = new VimeoVideo(iFrame.contentWindow, iFrame);
  }

  play() {
    this.video.play();
  }

  pause() {
    this.video.pause();
  }
}
