$(document).ready(function() {
  const defaultDatepickerArgs = {
    format: 'M dd yyyy',
    autoclose: true,
    clearBtn: true
  }

  const initializeDatepickers = function() {
    const pseudoSelectors = "not(.datepicker-initialized, .skip-datepicker-initialization)"
    $(`input.datepicker:${pseudoSelectors}, input.datepicker-year-month:${pseudoSelectors}`).each(function (_, element) {
      var datepickerOptions = defaultDatepickerArgs

      if (element.classList.contains("datepicker-year-month")) {
        datepickerOptions = $.extend(datepickerOptions, {
          format: "mm/yyyy",
          startView: "months",
          minViewMode: "months"
        })
      }

      // The defaultViewDate is provided by Bootstrap Datepicker but not supported as a data element.
      // If we provide the data element we'll need to provide it as an option for it to work as expected.
      // See: https://bootstrap-datepicker.readthedocs.io/en/latest/options.html#defaultviewdate
      if (element.dataset.defaultViewDate) {
        datepickerOptions = $.extend(datepickerOptions, { defaultViewDate: element.dataset.defaultViewDate })
      }

      $(element)
        .datepicker(datepickerOptions)
        .on('changeDate', function() {
          // We need to retain the focus on this element after selecting a date so that pressing Enter
          // will allow us to submit the form.
          $(this).datepicker('hide').focus()

          // Autosave is initialized before datepicker
          // this custom event allows us to intercept changes
          let event = new Event("datepickerChange")
          $(this).closest("form").get(0).dispatchEvent(event)
        })
        .addClass('datepicker-initialized')
    })
  }

  window.initializeDatepickers = initializeDatepickers

  initializeDatepickers()

  $(document).on('shown.bs.modal', initializeDatepickers)

  // Allows us to set the startDate or endDate of a datepicker input when changing another datepicker input.
  //
  // EG: To cause "end_date" to be at most 4 months in front and at least 1 day in front of "start_date":
  //   <%=
  //     f.input :start_date, as: :calendar,
  //       input_html: {
  //         autocomplete: "off",
  //         class: "datepicker",
  //         "data-updates-input" => "#end_date",
  //         "data-set-end-date-length" => "4",
  //         "data-set-end-date-unit" => "month",
  //         "data-set-start-date-length" => "1",
  //         "data-set-start-date-unit" => "day"
  //       }
  //   %>
  //   <%= f.input :end_date, as: :calendar, input_html: { class: "datepicker", id: "end_date" } %>
  $('[data-updates-input]').on('changeDate', function(event) {
    const $datepicker = $(event.currentTarget)
    const $targetDatepicker = $($datepicker.data('updates-input'))

    if ($datepicker.data('set-start-date-length')) {
      const lengthOfChange = $datepicker.data('set-start-date-length')
      const unitOfChange = $datepicker.data('set-start-date-unit')

      if (unitOfChange == 'day') {
        const newDate = $datepicker.datepicker('getDate')
        newDate.setMonth(newDate.getMonth(), newDate.getDate() + lengthOfChange)
        $targetDatepicker.datepicker('setStartDate', newDate)

        // If the date selected is before the new limit, we blank it out so the user must fill it in
        // Note - 'getDate' returns null if there is a date set but it is invalid (ie: before the startDate)
        const existingDate = $targetDatepicker.datepicker('getDate')
        if (existingDate == undefined) {
          // This will set the default date on the datepicker when you open it to the given date
          $targetDatepicker.datepicker('destroy')
          $targetDatepicker.datepicker($.extend(defaultDatepickerArgs, {'defaultViewDate': newDate, 'startDate': newDate}))

          $targetDatepicker.datepicker('setDate', '')
          $targetDatepicker.datepicker('hide') // 'setDate' will focus the calendar
          $targetDatepicker.blur()
        }
      } else {
        console.error('Unsupported unitOfChange provided: ' + unitOfChange)
      }
    }

    if ($datepicker.data('set-end-date-length')) {
      const lengthOfChange = $datepicker.data('set-end-date-length')
      const unitOfChange = $datepicker.data('set-end-date-unit')

      if (unitOfChange == 'month') {
        const newDate = $datepicker.datepicker('getDate')
        newDate.setMonth(newDate.getMonth() + lengthOfChange)

        // If a date-end-date is specified on the target date picker and it is less then the new
        // date, we want to set the date-end-date.
        //
        const endDate = new Date($targetDatepicker.data('dateEndDate'))
        if (endDate !== 'Invalid Date' && endDate < newDate) {
          $targetDatepicker.datepicker('setEndDate', endDate)
        } else {
          $targetDatepicker.datepicker('setEndDate', newDate)
        }

        // If the date selected is outside the new limit, we blank it out.
        const existingDate = $targetDatepicker.datepicker('getDate')
        if (existingDate == undefined) {
          $targetDatepicker.datepicker('setDate', '')
          $targetDatepicker.datepicker('hide') // 'setDate' will focus the calendar
          $targetDatepicker.blur()
        }
      } else {
        console.error('Unsupported unitOfChange provided: ' + unitOfChange)
      }
    }
  })
})

// User enters a rubbish date
$(document).on('blur', '.datepicker', function() {
  const element = this
  const inputValue = element.value

  if (inputValue.length == 0) { return }

  const date = new Date(inputValue)

  // Default format is M dd yyyy e.g. Jan 01 2022, Mar 10 2023
  //
  // We were sometimes getting dates in other languages like 01 de mayo de 2022
  // so this enforces the date is roughly in the format we expect.
  //
  // We intend to clear the field value and show the user some errors has occurred by
  // temporarily showing the field as having an error.
  //
  if (!/[A-Z][a-z]{2} \d{2} \d{4}/.test(inputValue)|| (date.getFullYear() > 9999)) {
    $(element).datepicker("update", "")
    element.classList.add("error", "background-color-smooth-transition")

    // The user may update the field within the 2s timeout in which case we should probably run
    // the timeout function earlier but this is probably not worth the complexity of
    // storing a timeout function per element on screen which would be required.
    //
    setTimeout(() => { element.classList.remove("error") }, 2000)
  }
})
