$(document).on('ajax:success', '.status-reason-changer a', (event) => {
  const data = event.detail[0]
  // Element.closest() not supported natively by IE
  const $target = $(event.target)
  const statusReasonChanger = $target.closest('.status-reason-changer')
  const button = statusReasonChanger.find('button')

  button.html(data.reason + ' <span class="caret"></span>').
    val(data.reason).
    dropdown("toggle")
})

$(document).on('ajax:error', '.status-reason-changer a', () => {
  alert('There was an error trying to set the status reason')
})

$(document).on("click", ".status-reason-changer a", (event) => {
  // Prevent dropdown from closing on link clicked, we'll control that above
  // after AJAX finishes
  event.stopPropagation()
})
