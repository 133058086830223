class Alert {  
  constructor({ title, body, type } = {}) {
    this.title = title;
    this.body = body;
    this.type = type;
  }

  html() {
    const titleElement = this.title ? `<strong>${ this.title }</strong>` : '';

    return(`<div class="alert alert-${ this.type } margin-bottom">
      ${ titleElement }
      <span>${ this.body }</span>
    </div>`);
  }
}

window.Alert = Alert
