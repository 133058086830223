class SelectAllCheckBoxesInput {
  constructor(element) {
    this.element = element
    this.selectAllCheckBox = this.element.querySelector(".js-select-all-check-box")
    this.checkBoxInputs = this.element.querySelectorAll(".check-boxes input[type='checkbox']")

    this._setupEvents()
  }

  _setupEvents() {
    this.selectAllCheckBox.addEventListener("change", this._selectAllCheckBoxClicked.bind(this))
    this.checkBoxInputs.forEach(checkBox => checkBox.addEventListener("change", this._checkBoxInputClicked.bind(this)))
  }

  _selectAllCheckBoxClicked() {
    this.checkBoxInputs.forEach(checkBox => checkBox.checked = this.selectAllCheckBox.checked)
  }

  _checkBoxInputClicked() {
    this.selectAllCheckBox.checked = this._checkedCheckBoxes().length == this.checkBoxInputs.length
  }

  _checkedCheckBoxes() {
    return Array.from(this.checkBoxInputs).filter(checkBox => checkBox.checked)
  }
}

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".js-select-all-check-boxes-input").forEach(element => {
    new SelectAllCheckBoxesInput(element)
  })
})
