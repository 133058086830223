/* globals VimeoEmbedController: true */
$(document).ready(function() {
  const $modalTrigger = $('#js-orientation-video-modal-trigger');
  if ($modalTrigger.length > 0) {
    const $modal = $($modalTrigger.data('modal-target'));
    const iframe = $($modalTrigger.data('iframe-target'))[0];
    const setStartedAtURL = $modalTrigger.data('set-started-at-path');

    $modal.on('hidden.bs.modal', function() {
      new VimeoEmbedController(iframe).pause();
    });

    $modal.on('shown.bs.modal', function() {
      new VimeoEmbedController(iframe).play();
      // Make a request to ensure the started_at value is set
      $.ajax({
        method: 'PATCH',
        url: setStartedAtURL,
        success: function() {
          // This is for testing purposes only. Use .attr vs .data as .attr changes the DOM
          $modal.attr('data-started-at-set', true);
        }
      });
    });

    $modalTrigger.click(function() {
      $modal.modal({backdrop: 'static'});
    });
  }
});
