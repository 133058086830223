/* globals PaymentForm: true */
/* globals PaymentFormField: true */
/* globals Button: true */

class PublicPaymentForm {
  constructor($form) {
    this.$form = $form;
  }

  initializeForm() {
    this.submitButton = new Button(this.$form.find('#js-submit-button'));
    this._assignInputs();
    this._initializeInputValidations();

    this.$paymentForm = new PaymentForm(this.$form, this.$form.data('client-authorization'), this.inputs, this.submitButton);
    this.$paymentForm.initializeForm();
  }

  _assignInputs() {
    this.inputs = {};
    this.inputs.firstName = new PaymentFormField($('#js-first-name'), { required: true });
    this.inputs.lastName = new PaymentFormField($('#js-last-name'), { required: true });
    this.inputs.emailForReceipt = new PaymentFormField($('#js-email-for-receipt'), { required: true });
    this.inputs.paymentAmount = new PaymentFormField($('#js-payment-amount'), { required: true });
    this.inputs.program = new PaymentFormField($('#js-program'), { required: true });
    this.inputs.invoiceNumber = new PaymentFormField($('#js-invoice-number'), { required: false });
    this.inputs.payingOnBehalfOfParticipant = new PaymentFormField($('#js-paying-on-behalf-of-participant'), { required: true });
    // These three fields are dependent on the above field
    this.inputs.participantFirstName = new PaymentFormField($('#js-participant-first-name'), { required: false });
    this.inputs.participantLastName = new PaymentFormField($('#js-participant-last-name'), { required: false });
    this.inputs.participantEmail = new PaymentFormField($('#js-participant-email'), { required: false });
    this.inputs.companyName = new PaymentFormField($('#js-company-name'), { required: false });
    this.inputs.phoneNumber = new PaymentFormField($('#js-phone-number'), { required: false });
    this.inputs.cardholderName = new PaymentFormField($('#js-cardholder-name'), { required: true });
    this.inputs.number = new PaymentFormField(this.$form.find('#card-number').closest('.form-group'), { braintreeHostedField: true, required: true });
    this.inputs.expirationDate = new PaymentFormField(this.$form.find('#cc-expiration').closest('.form-group'), { braintreeHostedField: true, required: true });
    this.inputs.cvv = new PaymentFormField(this.$form.find('#cvv').closest('.form-group'), { braintreeHostedField: true, required: true });
    this.inputs.comments = new PaymentFormField(this.$form.find('#js-comments'), { required: false });
  }

  _initializeInputValidations() {
    const emailValidationRegexp = new RegExp(this.$form.data('email-validation-regexp'));
    const emailValidator = function(paymentFormField) {
      if (paymentFormField.isPopulated()) {
        const isValidEmail = emailValidationRegexp.exec(paymentFormField.getValue()) != null;
        paymentFormField.setIsValid(isValidEmail);
      }
    };

    this.inputs.emailForReceipt.addValidator(emailValidator);
    this.inputs.participantEmail.addValidator(emailValidator);

    this.inputs.paymentAmount.addValidator(function(paymentFormField) {
      paymentFormField.setIsValid(parseFloat(paymentFormField.getValue()) >= 1.0);
    });

    // Set whether or not these fields are required based on a radio button.
    const participantFields = [
      this.inputs.participantFirstName,
      this.inputs.participantLastName,
      this.inputs.participantEmail
    ];
    $.each(participantFields, (index, input) => {
      input.setRequired(this.inputs.payingOnBehalfOfParticipant.getValue() == 'Yes');
    });

    this.inputs.payingOnBehalfOfParticipant.getInput().on('change', () => {
      $.each(participantFields, (index, input) => {
        input.setRequired(this.inputs.payingOnBehalfOfParticipant.getValue() == 'Yes');
        input.resetValidState();
      });
    });

    $.each(this.inputs, (index, input) => {
      // On initialization we want to validate the field. This is for cases when the form is re-rendered
      // with a valid value after submitting an otherwise invalid form.
      if (!input.isBraintreeHostedField && input.isPopulated()) {
        input.validate();
      }
    });
  }
}

$(document).ready(function() {
  const $form = $('#js-public-payment-form');
  if ($form.length > 0) {
    window.publicPaymentForm = new PublicPaymentForm($form);
    window.publicPaymentForm.initializeForm();
  }
});
