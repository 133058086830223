// This has been deprecated in favour of src/js/application/simple_select_toggle_elements.js
//
$(function() {
  $(document).on('change', '.toggler input[type=checkbox]', function() {
    const checked = $(this).prop('checked');
    const toggleGroup = $(this).closest('.toggle-group');

    toggleGroup.find('.toggled').toggle(checked).find('input, textarea').prop('disabled', !checked);
    toggleGroup.find('.untoggled').toggle(!checked).find('input, textarea').prop('disabled', checked);
  });

  // This can be used when you have a collection of checkboxes and you want a specific checkbox to toggle
  // an input underneath it.
  //
  $(document).on('change', '.js-toggler-with-specific-value input[type=checkbox]', function() {
    const $checkboxCollection = $(this).parents('.check_boxes');
    const checked = $(this).prop('checked');
    const checkedValue = $(this).prop('value');
    const toggleValue = $checkboxCollection.data('toggle-value');

    if(checkedValue == toggleValue) {
      $checkboxCollection.next().toggle(checked);
    }
  });
});
