class ConfirmableSubmitButton {
  static initialize() {
    document.querySelectorAll(".js-confirm-submit:not([data-js-confirm-submit-initialized])").
      forEach(element => new this(element));
  }

  constructor(element) {
    this.element = element
    this.initialButtonText = this.element.value
    this.form = this.element.closest("form")

    this.setupConfirm = this.setupConfirm.bind(this)
    this.reset = this.reset.bind(this)
    this.submit = this.submit.bind(this)

    this.setup()
  }

  setup() {
    this.element.addEventListener("click", this.setupConfirm)

    this.element.setAttribute("data-js-confirm-submit-initialized", true)
  }

  setupConfirm(event) {
    event.preventDefault()

    if (this.needsCancelButton()) {
      this.element.parentNode.insertBefore(this.cancelButton(), this.element.nextSibling)
    }

    this.element.value = "Confirm"
    this.element.addEventListener("click", this.submit)
    this.element.removeEventListener("click", this.setupConfirm)
  }

  submit(event) {
    // I'm not sure that the preventDefault is necessary here, but I'm leaving it in for now
    // In the instance where the confirm button has data-disable-with, the preventDefault
    // is skipped to allow the Rails UJS to handle the submit.
    if (!this.element.dataset.disableWith) {
      event.preventDefault()
      this.element.removeEventListener("click", this.submit)
    }

    if (this.form.dataset.remote) {
      Rails.fire(this.form, "submit")
    }
    else {
      this.form.submit()
    }
  }

  cancelButton() {
    const button = document.createElement("BUTTON")
    button.classList.add("js-cancel-button", "btn", "btn-default")
    button.innerText = "Cancel"
    button.addEventListener("click", this.reset)

    return button
  }

  needsCancelButton() {
    return(!this.element.parentNode.querySelector(".js-cancel-button"))
  }

  reset(event) {
    event.preventDefault()

    this.element.removeEventListener("click", this.submit)
    this.element.addEventListener("click", this.setupConfirm)
    this.element.innerText = this.initialButtonText
    this.element.value = this.initialButtonText
    this.element.nextSibling.remove()
  }
}

document.addEventListener("DOMContentLoaded", () => {
  ConfirmableSubmitButton.initialize()
})

window.ConfirmableSubmitButton = ConfirmableSubmitButton
