class ConfirmDialog {
  constructor(message) {
    this.message = message
  }

  onConfirm(callback) {
    this.onConfirm = callback
  }

  onCancel(callback) {
    this.onCancel = callback
  }

  show() {
    let $confirmDialog = $('#js-confirm-dialog')

    // Prevent users from opening a modal while a modal is already open
    if ($confirmDialog.length == 0) {
      const confirmDialogHtml = `
        <div id="js-confirm-dialog" class="modal" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                <p>${ this.message }</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary js-dialog-confirm-btn" data-dismiss="modal">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      `

      $confirmDialog = $(confirmDialogHtml)
    }

    const confirmButton = $confirmDialog[0].querySelector('.js-dialog-confirm-btn')
    $confirmDialog.modal({ show: true })
    const backdrops = document.querySelectorAll(".modal-backdrop.show")
    const newBackdrop = backdrops[backdrops.length - 1]
    newBackdrop.id = "js-confirm-dialog-backdrop"
    $confirmDialog.on('hidden.bs.modal', function() { $confirmDialog.remove() })
    confirmButton.addEventListener('click', this.onConfirm)

    if (this.onCancel) {
      $confirmDialog.on('hidden.bs.modal', this.onCancel)
    }
  }
}

const { stopEverything } = Rails

const handleConfirm = function(e) {
  if (!allowAction(this)) {
    stopEverything(e)
  }
}

window.confirmDialog = (message) => {
  const confirmDialog = new ConfirmDialog(message)
  const promise = new Promise((resolve) => {
    confirmDialog.onConfirm(() => { resolve(true) })
    confirmDialog.onCancel(() => { resolve(false) })
  })

  confirmDialog.show()

  return(promise)
}

Rails.delegate(document, '[data-confirm]', 'click', handleConfirm)

const allowAction = element => {
  if (element.dataset.confirm === null) {
    return true
  }

  const confirmDialog = new ConfirmDialog(element.dataset.confirm)
  confirmDialog.onConfirm(() => {
    // TODO: should we retain confirmation?
    // If they confirm a link once it means its confirmation will be disabled after that which
    // is probably unexpected
    element.removeAttribute('data-confirm')
    element.click()
  })
  confirmDialog.show()

  return false // Prevents Rails UJS showing the default confirm alert
}
