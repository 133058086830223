$(document).ready(function() {
  $('.js-orientation-toggle-complete').each(function (_, element) {
    new ParticipantOrientationToggleComplete($(element));
  });
});

// This class is used for Staff views where the Participant's Orientation status can be toggled between
// "Complete" and "Not Complete".
//
// When the status is toggled, the end-point will return a HTML snippet of the updated status that should
// be rendered on the page.
//
class ParticipantOrientationToggleComplete {
  constructor($participantOrientationCompleteToggle) {
    this.$participantOrientationCompleteToggle = $participantOrientationCompleteToggle;
    this.$destinationElement = $(this.$participantOrientationCompleteToggle.data('orientation-toggle-destination-element'));
    this.$loaderElement = $(this.$participantOrientationCompleteToggle.data('orientation-toggle-loader-element'));
    this.url = this.$participantOrientationCompleteToggle.data('orientation-toggle-path');

    $participantOrientationCompleteToggle.change(() => {
      this.updateCompleteStatus();
    });
  }

  updateCompleteStatus() {
    this.setIsLoading(true);

    $.ajax({
      method: 'PATCH',
      url: this.url,
      complete: () => {
        this.setIsLoading(false);
      },
      success: (data) => {
        this.$destinationElement.html(data);
      },
      error: () => {
        // Reverse the operation on the checkbox. Use prop here so the event is not fired again.
        this.$participantOrientationCompleteToggle.prop('checked', !this.$participantOrientationCompleteToggle.prop('checked'));
        alert('There was an issue changing the status of the orientation. Please refresh your browser and try again.');
      }
    });
  }

  setIsLoading(isLoading) {
    this.$participantOrientationCompleteToggle.prop('disabled', isLoading);
    this.$participantOrientationCompleteToggle.toggleClass('d-none', isLoading);
    this.$loaderElement.toggleClass('d-none', !isLoading);
  }
}
