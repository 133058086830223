$(document).ready(function() {
  $('.filterable-nested-checkboxes').each(function (_, element) {
    new FilterableNestedCheckboxGroup($(element));
  });
});

class NestedCheckboxGroup {
  constructor($nestedListGroup) {
    this.categories = this._buildCategories($nestedListGroup);
  }

  _buildCategories($nestedListGroup) {
    const categories = [];

    $nestedListGroup.find('.list-group-category').each(function (_, element) {
      categories.push(new NestedCheckboxCategory($(element)));
    });

    return categories;
  }
}

class FilterableNestedCheckboxGroup extends NestedCheckboxGroup {
  constructor($filterableNestedCheckboxGroup) {
    super($filterableNestedCheckboxGroup.find('.list-group-nested'));

    this.$filterInput = $filterableNestedCheckboxGroup.find('.search-field');

    this._initializeFilterInput();
  }

  _initializeFilterInput() {
    const nestedCheckboxGroup = this;

    this.$filterInput.on('input', (event) => {
      event.stopPropagation();
      const filterValue = event.currentTarget.value.toLowerCase();

      Array.from(nestedCheckboxGroup.categories).forEach((category) => {
        category.search(filterValue);
      });
    });
  }
}

class NestedCheckboxCategory {
  constructor($category) {
    this.$title = $category.find('.js-category-title-item');
    this.$items = $category.find('.js-category-item');

    this._initializeTitleClick();
  }

  search(filterValue) {
    const titleValue = this._getItemValue(this.$title[0]);
    const match = titleValue.toLowerCase().includes(filterValue);

    this.$title.toggle(match);
    this.$items.toggle(match);

    this._searchItems(filterValue);
  }

  _getItemValue($item) {
    return $item.textContent || $item.innerText;
  }

  _initializeTitleClick() {
    const category = this;

    this.$title.on('change', 'input[type=checkbox]', function() {
      const checked = $(this).prop('checked');

      category.$items.find('input[type="checkbox"]:visible').prop('checked', checked);
    });
  }

  _searchItems(filterValue) {
    Array.from(this.$items).forEach((item) => {
      const itemValue = this._getItemValue(item);
      const match = itemValue.toLowerCase().includes(filterValue);

      if (match) {
        $(item).show();
        this.$title.show();
      }
    });
  }
}
