class InitialTrainingPlanMover {
  constructor(modal) {
    this.modal = modal
    this.submitButton = modal.find('button.search-button')
    this.searchContentContainer = modal.find('.search-result-content')
    this.beforeSearchContentContainer = modal.find('.no-search-content')
    this.duringSearchContentContainer = modal.find('.loading-search-content')

    modal.on('ajax:send', this.searchStarting.bind(this))
    modal.on('ajax:complete', this.searchFinished.bind(this))
    modal.on('ajax:success', this.searchSucceeded.bind(this))
    modal.on('ajax:error', this.searchFailed.bind(this))
    modal.on('click', '.operation-button', this.moveStarted.bind(this))
    modal.find('.search-button').attr('disabled', false)
  }

  searchStarting() {
    this.submitButton.button('loading')
    this.searchContentContainer.hide()
    this.beforeSearchContentContainer.hide()
    this.duringSearchContentContainer.show()
  }

  searchFinished() {
    this.submitButton.button('reset')
    this.duringSearchContentContainer.hide()
  }

  searchSucceeded(event) {
    const content = event.detail[0]
    this.searchContentContainer.show()
    this.searchContentContainer.html(content.body.innerHTML)
  }

  searchFailed(response, status, xhr) {
    console.error('failed', 'response', response, 'status', status, 'xhr', xhr)
    alert('There was a failure searching for Employers')
  }

  moveStarted() {
    this.modal.find('.operation-button').attr('disabled', true)
  }
}

$(document).on('show.bs.modal', '.move-training-plan-modal', (event) => {
  if (!event.target.dataset.initialized) {
    const modal = $(event.target)
    new InitialTrainingPlanMover(modal)
    event.target.dataset.initialized = true
  }
})
